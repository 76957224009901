.pagemain {
    position: absolute;
    display: flex;
    flex-flow: column;
    // justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    margin-top: 370px;
    .scroller{
        width: 840px;
        height: auto;
        max-height: calc(108px * 5 - 10px);
        margin-left: 20px;
    }
    .radioItem{
        width: 800px;
    }
    .radioItem:last-child{
        margin-bottom: 0px;
    }
}


@__DEV__: true;