@import '../../style/CommonStyle.module.less';

@keyframes topGroupExtend {
    from {
        margin-left: 0;
      }
     to {
        margin-left: 50%;
     }
}
@keyframes topGroupReduce {
    from {
        margin-left: 50%;
      }
     to {
        margin-left: 0;
     }
}
.top-title-layer{
    height: @toplayerHeight;
    position: absolute;
    width: 100%;
}
.listMain {
	height: 200px;
    white-space: nowrap;
    &.big{
        height: auto;
    }
}
.hCenterAlign{
	width: auto;
    max-width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    display: table;
    position: relative;
}
.globalbutton {
    float: right;
    margin-top: 36px;
    margin-right: 78px;
    .account {
        display: inline-block;
    }
    :global(.enyo-locale-right-to-left) & {
        float: left;
        margin-right: 0px;
        margin-left: 0.5rem;
    }
}

@__DEV__: true;