@import '../../../style/CommonStyle.module.less';

.panel {
	.text {
		position: relative;
		color: darkgray;
		font-size: 30px;
		margin-left: 73px;
		font-family: "LG SmartFont";
	}

	.goalListBg {
		position: relative;
		margin-top: 30px;
		margin-left: 70px;
		border-radius: 24px;
		.goalListContainer{
			position: relative;
			padding: 0px;
			transform: translateX(0%);
			top: 0px;
		}
	}
}
@__DEV__: true;