.dayPicker{
    display: flex;
    margin-top: 0px;
    margin-left: 0px;

    > div:nth-child(1) >{
        width: 244px;
        height: 52px;
        margin-left: -5px;
        background-color: rgba(255,255,255,0.16);
        border: 1px solid rgb(139,133,151);
        border-radius: 26px;
    &:focus{
      transform: scale(1.05);
      color:white !important;
      > div:nth-child(1){
        width: 244px;
        height: 52px;
        border-radius: 26px;
        background: linear-gradient(to right, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75));
      }
    }
  }
    > div:nth-child(2) >{
        width: 244px;
        height: 52px;
        margin-left: -5px;
        background-color: rgba(255,255,255,0.16);
        border: 1px solid rgb(139,133,151);
        border-radius: 26px;
        &:focus{
            transform: scale(1.05);
            color:white !important;
            > div:nth-child(1){
            width: 244px;
            height: 52px;
            border-radius: 26px;
            background: linear-gradient(to right, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75));
            }
        }
     }
    > div:nth-child(3) >{
        width: 244px;
        height: 52px;
        margin-left: -5px;
        background-color: rgba(255,255,255,0.16);
        border: 1px solid rgb(139,133,151);
        border-radius: 26px;
        &:focus{
            transform: scale(1.05);
            color:white !important;
            > div:nth-child(1){
                width: 244px;
                height: 52px;
                border-radius: 26px;
                background: linear-gradient(to right, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75));
            }
        }
    }
    > div:nth-child(4) >{
        width: 244px;
        height: 52px;
        margin-left: -5px;
        background-color: rgba(255,255,255,0.16);
        border: 1px solid rgb(139,133,151);
        border-radius: 26px;
        &:focus{
            transform: scale(1.05);
            color:white !important;
            > div:nth-child(1){
                width: 244px;
                height: 52px;
                border-radius: 26px;
                background: linear-gradient(to right, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75));
            }
        }
    }
    > div:nth-child(5) >{
        width: 244px;
        height: 52px;
        margin-left: -5px;
        background-color: rgba(255,255,255,0.16);
        border: 1px solid rgb(139,133,151);
        border-radius: 26px;
        &:focus{
            transform: scale(1.05);
            color:white !important;
            > div:nth-child(1){
                width: 244px;
                height: 52px;
                border-radius: 26px;
                background: linear-gradient(to right, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75));
            }
        }
    }
    > div:nth-child(6) >{
        width: 244px;
        height: 52px;
        margin-left: -5px;
        background-color: rgba(255,255,255,0.16);
        border: 1px solid rgb(139,133,151);
        border-radius: 26px;
            &:focus{
            transform: scale(1.05);
            color:white !important;
            > div:nth-child(1){
                width: 244px;
                height: 52px;
                border-radius: 26px;
                background: linear-gradient(to right, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75));
            }
        }
    }
    > div:nth-child(7) >{
        width: 244px;
        height: 52px;
        margin-left: -5px;
        background-color: rgba(255,255,255,0.16);
        border: 1px solid rgb(139,133,151);
        border-radius: 26px;
        &:focus{
            transform: scale(1.05);
            color:white !important;
            > div:nth-child(1){
                width: 244px;
                height: 52px;
                border-radius: 26px;
                background: linear-gradient(to right, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75));
            }
        }
    }
}
@__DEV__: true;