@import '../../style/CommonStyle.module.less';

.homebanner{ //default big
    position: relative;
    /*big*/
    height: @bannerBig;
    .infoArea {
        padding-top: 0px;
        margin-left: 73px;
        height: auto;
        position: relative;
    }
    .title{
        position: relative;
        font-family: "LG SmartFont Bold";
        width: 1000px;
        height: 76px;
        padding-top: 60px;
        font-size: 74px;
        line-height: 84px;
        font-weight: bold;
        letter-spacing: 0px;
        vertical-align: middle;
        text-align: left;
        color: rgb(255, 255, 255);
        // will-change: transform;
    }
    .subtitle{
        position: relative;
        display: block;
        font-family: "LG SmartFont";
        width: 1000px;
        height: 180px;
        font-size: 34px;
        line-height: 47px;
        word-break:keep-all;
        font-weight: lighter;
        overflow: hidden;
        margin-top: 46px;
        text-align: justify;
        text-justify: inter-word;
        // will-change: transform;
        white-space: pre-wrap;
    }
    .moreInfo{
        width: 1050px;
        margin-top: 54px;
    }
    .buttonArea{
        position: absolute;
        margin-top: 74px;
        margin-left: 240px;
    }
    .bannerPreview{ //image banner
        position: fixed;
        top:  ~"calc(@{toplayerHeight} + 22px)";
        min-width: 100%;
        height: 864px;
        margin: 0px;
        background-size: cover;
        background-position: top;
        right: 0px;
        z-index: -1;
        mask-size: 100% 100%;
        -webkit-mask-size: 100% 100%;
        mask-image: url(../../../assets/banner/preview_big_mask.png);
        -webkit-mask-image: url(../../../assets/banner/preview_big_mask.png);
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
    }
    .preview{
        position: fixed;
        top:  ~"calc(@{toplayerHeight} + 22px)";
        width: 1313px;
        height: 734px;
        margin: 0px;
        background-size: cover;
        background-position: top;
        right: 0px;
        z-index: -1;
    }
    .localGuide{
        color: red;
        z-index: 0;
        text-align: center;
        padding-top: 5%;
    }
    .previewMask{
        // background-image: url(../../../assets/banner/violet_videocover_big.png);
        top:  ~"calc(@{toplayerHeight} + 21px)";
        width: 1315px;
        height: 738px;
    }
    &.medium {
        height:  @bannerMedium;
        .infoArea {
            padding-top: 0;
        }
        .title{
            font-family: "LG SmartFont Bold";
            padding-top: 40px;
            font-size: 74px;
            line-height: 74px;
        }
        .subtitle{
            font-family: "LG SmartFont";
            margin-top: 24px;
            width: 1000px;
            height: 180px;
            text-align: justify;
            text-justify: inter-word;
        }
        .moreInfo{
            width: 1050px;
            margin-top: 30px;
        }
        .bannerPreview{
            height: 628px;
            top: 0px; // top:  ~"calc(-@{toplayerHeight} + 1px)";
        }
        .preview{
            width: 1122px;
            height: 628px;
            top: 0px; // top:  ~"calc(-@{toplayerHeight} + 1px)";
        }
        .previewMask{
            width: 1124px; // 1123px
            height: 630px; // 632px
            top:  0px; // top:  ~"calc(-@{toplayerHeight})";
            // background-image: url(../../../assets/banner/violet_videocover_medium.png);
        }
    }
    &.small {
        height: @bannerSmall;
        .infoArea {
            padding-top: 0;
        }
        .title{
            font-family: "LG SmartFont Bold";
            padding-top: 0px;
            font-size: 60px;
            height: 60px;
            width: 1000px;
        }
        .subtitle{
            font-family: "LG SmartFont";
            margin-top: 18px;
            width: 1100px;
            height: 62px;
            font-size: 27px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .bannerPreview{
            height: 320px;
            top:  0px;
        }
        .preview{
            width: 855px;
            height: 478px;
            top:  0px;
        }
        .previewMask{
            width: 857px;
            height: 480px;
            top:  0px;
            // background-image: url(../../../assets/banner/violet_videocover_small.png);
        }
        .moreInfo{
            /* hide subtitle */
            // width: 780px;
            // height: 82px;
            // margin-top: 12px;

            width: 1580px;
            top: 110px;
            height: 82px;
            margin-top: 5px;
            position: absolute;
            left: 0px;
        }
    }
}
.bannerWrap{
    width: 100%;
    height: 100%;
    .bannerBtnWrap{
        position: absolute;
        display: flex;
        top: 350px;
        left: 175px;
        width: 550px;
        height: 85px;
        .bannerDeepButton{
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            min-width: 300px;
            max-width: 100%;
            height: 100%;
            border-radius: 44px;
            border: 5px solid white;
            background: linear-gradient(0deg, rgba(95, 83, 83, 0.651), rgba(121, 103, 103, 0.575));
            &.hidden {
                opacity: 0;
            }
            &:not(.hidden){
                transition: opacity 500ms ease-in-out;
            }
            .text{
                margin: 0 60px 0 60px;
                min-width: 280px;
                text-align: center;
                font-family: "LG SmartFont SemiBold";
                font-size: 28px;
                white-space: pre-wrap;
                line-height: 36px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2 ;
                -webkit-box-orient: vertical;
            }
            &:global(.spottable):focus{
                transform: scale(1.1);
                background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;
            }
        }
    }
}
.bannerWrap:hover{
    .bannerDeepButton{
        transform: scale(1.1);
        background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;
    }
}
.hide {
    opacity: 0;
}
@__DEV__: true;