.panel{
  background-color:white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
}
.nomal{
  width: 286px;
  height: 127px;
  position: absolute;
  left: 817px;
  top: 476px;
  background-size: contain;
  background-image: url('../../../assets/landingMotion/splashIMG_moving.jpg');
  background-repeat: no-repeat;
}
.splash {
  width: 286px;
  height: 127px;
  position: absolute;
  left: 817px;
  top: 476px;
  background-size: contain;
  animation: up-down 500ms infinite ease-in-out alternate;
  background-image: url('../../../assets/landingMotion/splashIMG_moving.jpg');
  background-repeat: no-repeat;
}
@keyframes up-down{
  from{
    transform: translatey(0px);
  }
  to{
    transform: translatey(-112px);
  }
}

.textImg{
  width: 286px;
  height: 41px;
  position: absolute;
  left: 817px;
  top: 655px;
  background-size: contain;
  background-image: url('../../../assets/landingMotion/splashTEXT_moving.jpg');
  background-repeat: no-repeat;
}
.text1{
  font-family: "LG SmartFont Bold";
  font-size: 14px;
  opacity: 0
}
.text2{
  font-family: "LG SmartFont";
  font-size: 14px;
  opacity: 0
}
.text3{
  font-family: "LG SmartFont Light";
  font-size: 14px;
  opacity: 0
}
.text4{
  font-family: "LG SmartFont SemiBold";
  font-size: 14px;
  opacity: 0
}

@__DEV__: true;