.panel{
	.title {
		position: relative;
		margin-top: 50px;
		margin-left: auto;
		margin-right: auto;
		width: 700px;
		height: 80px;
		font-size: 60px;
		font-family: 'LG SmartFont SemiBold';
	}

	.contentContainer{
		position: relative;
		display: inline-flex;
		margin-top : 50px;
		margin-left : 45px;
	}

	.buttonWrap{
		position: relative;
		margin-top: 30px;
		text-align: center;
		.button {
			position: relative;
		}
	}

	.reoderMode {
		pointer-events: none;
	}
}
@__DEV__: true;