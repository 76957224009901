@import '../../../../style/CommonStyle.module.less';

.startStopReset{
  background-color:#fff;
  font-family: "LG SmartFont SemiBold";
  font-size: 44px;
  width: 200px;
  height: 200px;
  border-radius:100%;
  line-height: 200px;
  color: #1a1a1a;
  border: solid 32px #363636;
  text-align: center;
  &:focus{
    background-color:#1a1a1a;
    border: solid 32px #fff;
    color: #fff;
    border: solid 32px #fff;
  }
}
@__DEV__: true;