@import '../../../style/CommonStyle.module.less';

.chart {
    .dashboards-chart-bg();
    width: 1756px;
    min-height: 380px;
    height: auto;
}
.itemContainer{
    height: 84px;

    &:first-child{
        margin-top: 42px;
    }
    &:last-child{
        margin-bottom: 42px;
    }
    .text{
        font-family: "LG SmartFont Light";
        font-size: 28px;
        color: white;
        line-height: 84px;
    }
    .date{
        width: 250px;
        float: left;
        margin-left: 45px;
    }
    .detail{
        width: auto;
        height: 100%;
        margin-left: 10px;
        margin-right: 45px;
        float: right;
        text-align: right;
        .text{
            display: inline;
        }
        .playtime{
            color: rgb(108,125,230)
        }
        .calorie{
            color: rgb(234,147,52)
        }
        .seperator{
            color: rgb(109,106,106)
        }
        .badgeStyle {
            width: 42px;
            height: 29px;
            border-radius: 0px;
            border: 0px;
            background-size: contain;
            background-repeat: no-repeat;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .title{
        width: auto;
    }
    &.odd{
        height: 60px;
        background-color: rgba(255,255,255,0.07);
        .text{
            line-height: 60px;
        }
    }


}
.empty_comment{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 31px;
    font-size: 28px;
    line-height: 1.29;
    word-break: keep-all;
    font-family: "LG SmartFont Light";
}
.hide{
    opacity: 0;
}
@__DEV__: true;