@import '../../../style/CommonStyle.module.less';

.panel {
	.title {
		position: relative;
		width:100%;
		text-align: center;
		font-size: 36px;
		font-family:'LG SmartFont Bold';
	}

	.alarm {
		position: relative;
		display: inline-flex;
		width: 100%;
		.alarmTitle {
			position: relative;
			margin-left: 72px;
			margin-top: 15px;
			font-size: 30px;
			font-family: "LG SmartFont";
		}
		.alarmSwitch {
			position: relative;
			width: 250px;
			height: 72px;
			border-radius: 26px;
			margin-left: 88px;
			font-size: 26px;
			font-family: "LG SmartFont";
			&:focus{
				> div:first-child{
				background-size: 100% 72px !important;
				}
			}
		}
	}

	.repeat {
		position: relative;
		width: 100%;
		.repeatTitle {
			position: relative;
			font-size: 30px;
			margin-left: 73px;
			font-family: "LG SmartFont";
		}
		.dayPicker {
			position: relative;
			margin-left: 73px !important;
			margin-top: 22px !important;
		}
	}

	.goalitem {
		display: flex;
		position: relative;
		margin-top: 17px;
		left: 73px;
	}

	.buttonContainer{
		position: relative;
		margin-top: 20px;
		text-align: center;
		.button {
			position: relative;
		}
	}
}
@__DEV__: true;