@import '../../../style/CommonStyle.module.less';
.buttonContainer {
    display: inline-block;
    position: absolute;
    width: auto;
    height: auto;
    margin-left: 17px;
    margin-right: 17px;
    transition: transform 200ms ease-out;
    .button{
        width: 156px;
        height: 68px;
        background-size: contain;
        background-repeat: no-repeat;
        transition: background-image 200ms ease-in-out;
    }
    &.pressed .button{
        transform: scale(@button-press-size);
    }
    .tooltip{
        position: absolute;
        left: 50.5%;
        transform: translateX(-50.5%);
    }
    .name {
        visibility: hidden;
        height: 16px;
        margin-top: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: -0.0642rem;
        text-align: center;
        color: rgb(255,255,255);
        font-family: "sandstone";
        white-space: nowrap;
    }
    &:focus .name {
        visibility: visible;
    }
    .selectBar{
        visibility: hidden;
        position: absolute;
        width: 52.0008px;
        height: 6px;
        top: 72px;
        left: 52.0008px;
        background-image:url(../../../../assets/top/menu_select_underbar.png);
    }
    &:not(:focus)&.selected {
        .selectBar{
            visibility: visible;
        }
    }
    opacity: 0;
    &.selected{
        opacity: 1;
    }
    &.extended{
        position: relative;
        opacity: 1;
    }
}
.category{
    .button{
        background-image:url(../../../../assets/top/menu_category_normal.png);
    }
    &.selected .button{
        background-image:url(../../../../assets/top/menu_category_select.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/menu_category_focus.png);
    }
}
.home{
    .button{
        background-image:url(../../../../assets/top/menu_home_normal.png);
    }
    &.selected .button{
        background-image:url(../../../../assets/top/menu_home_select.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/menu_home_focus.png);
    }
}
.dashboard{
    .button{
        background-image:url(../../../../assets/top/menu_dashboard_normal.png);
    }
    &.selected .button{
        background-image:url(../../../../assets/top/menu_dashboard_select.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/menu_dashboard_focus.png);
    }
}
.icon{
    width: 68px;
    height: 68px;
    margin-left: 0;
    margin-right: 0;
    .button{
        width: 68px;
        height: 68px;
        background-repeat: 68px 68px;
    }
}
@media (width:1280px){
    .icon{
        width: 45apx;
        height: 45apx;
        .button{
            width: 45apx;
            height: 45apx;
            background-repeat: 45apx 45apx;
        }
    }
}
.exit{
    .button{
        background-image:url(../../../../assets/top/exit-nor.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/exit-focus.png);
    }
}
.search{
    .button{
        background-image:url(../../../../assets/top/search-nor.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/search-focus.png);
    }
}
.settings{
    .button{
        background-image:url(../../../../assets/top/settings-nor.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/settings-focus.png);
    }
}
.login{
    .button{
        background-image:url(../../../../assets/top/login_nor.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/login_focus.png);
    }
}
.logout{
    .button{
        background-image:url(../../../../assets/top/logout_On.png);
    }
    &:global(.spottable):focus .button{
        background-image:url(../../../../assets/top/logout_Focus.png);
    }
}
@__DEV__: true;