.titleMain{
    font-family: 'LG SmartFont';
    width: 100%;
    position: fixed;
    font-size: 50px;
    top: 140px;
    text-align: center;

    .icon{
        width: 86px;
        height: 86px;
        background-image: url('../../../../../assets/interview/q-icon.png');
        background-size:cover;
        background-repeat: no-repeat;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .title{
        text-align: center;
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding-top:27px;
        span{
            background-image:url('../../../../../assets/interview/title-bg.png');
            background-size: 68px 53px;
            background-repeat: no-repeat;
            padding-left: 20px;
            padding-top: 20px;
            b{
                text-align: center;
                font-family: "LG SmartFont Bold";
            }
        }
    }
}
@__DEV__: true;