.infopopup{
	width: 950px !important;
	height: 600px;
	border-radius: 20px;
	border: 2px solid rgba(212, 214, 221, 0.753);
	background: linear-gradient(to right, rgba(8,59,100,0.47), rgba(5,49,100,0.46))!important;
	background-size: cover !important;
	opacity: 1;
	background-image: url(../../../../assets/list/popup_bg.png) !important;

	>div >div >div{
		width: 100% !important;
	} //임시
}
.titlelayer{
	margin-top:30px;
	.backBtn{
		display: inline-block;
	}
	.title{
		width: 800px;
		display: inline-block;
		white-space: nowrap;
		font-weight: bold;
		font-size: 60px;
		line-height: 70px;
		font-weight: bold;
		letter-spacing: -1.85px;
		text-align: left;
		color: rgb(255,255,255);
		margin-top:30px;
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
	}
}
.subtitle{
	font-size: 30px;
	line-height: 36px;
	overflow: hidden;
	margin-top: 34px;
	margin-left: 75px;
	width: 816px;
	height: 180px;
	color: rgb(255,255,255);
	text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
	white-space: pre-wrap;
}
.moreInfo{
	width: 816px;
	margin-top: 48px;
	margin-left: 75px;
}
.log{
	width: 708px !important;
	height: 400px;
	margin-bottom: 365px !important;
	.titlelayer{
		width: 650px;
		// margin-top:50px;
		margin-top:0;
		.title{
			width: 545px;
			display: inline-block;
			white-space: nowrap;
			font-weight: bold;
			font-size: 60px;
			line-height: 70px;
			font-weight: bold;
			letter-spacing: -1.85px;
			text-align: left;
			color: rgb(255,255,255);
			margin-top:30px;
			text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
		}
	}
	.subtitle{
		width: 100%;
		height: 70px;
		margin-top: 10px;
		margin-left: 0;
		margin-right: 0;
		text-align: center;
	}
	.moreInfo{
		position: absolute;
		width: 100%;
		margin-top: 24px;
		margin-left: 5px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
	}
	.buttonlayer{
		text-align: center;
    	// margin-top: 120px;
		margin-top: 95px;
			.replayBtn {
				vertical-align: top;
			}
	 }
	.listlayer{
		width: 100%;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 10px;
	}
	.qrcodeContainer {
		position: absolute;
		top: 20px;
		left: 700px;
		width: 480px;
		height: 360px;
		.qrcode {
			position: relative;
			background-color: white;
			margin-top: 25px;
			padding: 19px;
			left: 50%;
			width: 150px !important;
			height: 150px !important;
			transform: translate(-50%);
			border: 5px solid black;
		}
		.qrinfo {
			margin-top: 10px;
			font-family:  "LG SmartFont SemiBold";
			text-align: center;
			font-size: 26px;
			color: white;
			line-height: 1.5rem;
			white-space: pre-wrap;
		}
	}
}
@__DEV__: true;