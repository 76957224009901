@import '../../../style/CommonStyle.module.less';
.panel{
    top: 0px;
    .backBtn {
        position: absolute;
        top: 44px;
        left: 73px;
    }
    .exitBtn{
        position: absolute;
        top: 35px;
        left: 1769px;
    }
    .verticalCenter{
        position: absolute;
        width: 100%;
        height: auto;
        top: 150px;

        .title {
            font-size: 60px;
            line-height: 70px;
            font-family: "LG SmartFont";
            text-align: center;
        }
        .styleList {
            display: inline-block;
            margin-top: 108px;
        }
    }

}
@__DEV__: true;