@import '../../style/CommonStyle.module.less';

@BUTTON_WIDTH: 342px;
@BUTTON_HEIGHT: 72px;
@BUTTON_RIGHT: 112px;
@INFO_AREA_WIDTH: 247px;
@GOAL_DATA_LAYER_HEIGHT: 60px;

.Container {
    position: absolute;
    width: @INFO_AREA_WIDTH;
    height: @BUTTON_WIDTH;
    right: -@INFO_AREA_WIDTH;
    transition: transform 200ms ease-out;
    // top:  229px;
    top:  68px;
    &.hideButton {
        opacity: 0;
    }
    .button{
        position: absolute;
        width: @BUTTON_WIDTH;
        height: @BUTTON_HEIGHT;
        transform: rotate(270deg);
        transform-origin: center;
        top: 136px;
        right: @BUTTON_RIGHT;
        border-radius: 50px 50px 0px 0px;
		background: linear-gradient(0deg, rgba(95, 83, 83, 0.651), rgba(121, 103, 103, 0.575)) !important;
        box-shadow: inset 0px 0px 45px rgba(78, 71, 71, 0.445);
        border: solid 1px rgb(116, 116, 116);
        &:focus{
            width: 342px;
            height: 72px;
            right: 112px;
            border-radius: 50px 50px 0px 0px;
            background: linear-gradient(50deg, rgba(124, 135, 153, 0.84), rgba(127, 137, 155, 0.84), rgba(110, 91, 112, 0.82), rgba(115, 97, 117, 0.69)) !important;
            >div{
                font-size: 29px;
                line-height: 80px;
            }
        }
        >div{
            text-align: center;
            font-family: "LG SmartFont Bold";
            color: rgb(255, 255, 255);
            font-size: 28px;
            line-height: @BUTTON_HEIGHT;
        }
    }
    .emptyInfo{
        font-family: "LG SmartFont Light";
        width: 100%;
        font-size: 24px;
        line-height: 1.5;
        color: white;
        text-align: center;
        position: absolute;
        white-space: pre-wrap;
        top: 50%;
        transform: translateY(-50%);
    }
    .infoArea{
        width: 100%;
        height: 100%;
        position: relative;
        right: 0;
        border-radius: 0px;
		background: linear-gradient(0deg, rgba(73, 63, 63, 0.575), rgba(63, 54, 54, 0.349)) !important;
        box-shadow: inset 0px 0px 35px rgba(255, 255, 255, 0.205);
        border: solid 1px rgb(104, 104, 104);
        .totalInfo{
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .textBlock{
                display:inline-block;
                width: 100%;
                text-align: center;
                margin-top: 25px;
                margin-bottom: 25px;
                font-family: "LG SmartFont Bold";
                .title{
                    font-family: "LG SmartFont";
                    font-size: 18px;
                    color: white;
                    line-height: 24px;
                    margin-top: 10px;
                }
                .value{
                    font-size: 48px;
                    line-height: 60px;
                    display: inline-block;
                    margin-left: 5px;
                    margin-right: 5px;
                    &.marquee{
                        display: initial;
                    }
                }
                .value2{
                    color: rgb(167,167,167);
                    font-size: 30px;
                    line-height: 60px;
                    display: inline-block;
                    margin-left: 5px;
                    margin-right: 5px;
                    vertical-align: bottom;
                }
            }
        }
        .goalInfo{
            width: 100%;
            height: 100%;
            .goalTitle{
                width: 100%;
                height: 48px;
                font-family: "LG SmartFont Bold";
                font-size: 24px;
                line-height: 48px;
                text-align: center;
            }
            .goalTitleBar{
                width: 197px;
                height: 1px;
                margin-left: 20px;
                background-image: linear-gradient(to top, white, white);
            }
            .goalBody{
                width: 100%;
                height: 196px;
                position: relative;
            }
            .goalDataLayer{
                width: 100%;
                height: @GOAL_DATA_LAYER_HEIGHT;
                font-family: "LG SmartFont SemiBold";
                text-align: center;
                >div{
                    display: inline-block;
                    line-height: @GOAL_DATA_LAYER_HEIGHT;
                }
                >div:first-child{
                    font-size: 48px;
                    color: white;
                }
                >div:nth-child(2){
                    font-size: 30px;
                    margin-left: 5px;
                    margin-right: 5px;
                    color: rgb(167, 167, 167);
                }
                &.achieve{
                    >div:last-child{
                        font-size: 48px;
                        color: white;
                    }
                }
            }
            .goalSubTitle{
                width: 100%;
                font-family: "LG SmartFont";
                text-align: center;
                font-size: 18px;
                line-height: 28px;
            }
            .dots{
                position:absolute;
                bottom: 6px;
                width: 100%;
                height: 20px;
                height: 8px;
                bottom: 8px;
                text-align: center;
                .dot{
                    width: 6px;
                    height: 6px;
                    display: inline-block;
                    border: solid 1px white;
                    border-radius: 6px;
                    margin-left: 3px;
                    margin-right: 3px;
                    vertical-align: top;
                }
                .active{
                    background-color: white;
                }
            }
            .goalTotalLayer{
                width: 100%;
                height: @GOAL_DATA_LAYER_HEIGHT;
                font-family: "LG SmartFont SemiBold";
                text-align: center;
                >div{
                    display: inline-block;
                    line-height: @GOAL_DATA_LAYER_HEIGHT;
                }
                >div:first-child,
                >div:nth-child(3){
                    font-size: 48px;
                    color: white;
                }
                >div:nth-child(2),
                >div:nth-child(4){
                    font-size: 30px;
                    margin-left: 5px;
                    margin-right: 5px;
                    color: rgb(167, 167, 167);
                }
            }
        }
    }
    &.extended{
        transform: translateX(-@INFO_AREA_WIDTH)!important;
    }
}


@__DEV__: true;