@import '../../../style/CommonStyle.module.less';

@TOP_LAYER_HEIGHT: 50px;
@BOTTOM_LAYER_HEIGHT: 110px;
.buttonlayer{
    position: absolute;
    right: 0;
    top: 2px;
    .button{
        min-width: 110px !important;
        height: 38px !important;
        font-size: 18px;
        padding-top: 3px;
        margin: 0px 5px 0px 5px !important;
        >div:first-child{
            background-color: rgba(143,143,143,0.28);
            border: 2px solid rgba(114, 111, 111, 0.292);
            border-radius: 25px;
            box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
            filter: initial !important;
        }
        &.selected{
            >div:first-child{
                background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) ;
            }
        }
        &.disabled{
            >div:first-child{
                background-color: rgba(78, 76, 76, 0.418);
            }
        }
        &:focus{
            color: rgb(233,233,233) !important;
            >div:first-child{
                background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) ;
            }
            &.disabled >div:first-child{
                background-color: rgb(0, 0, 0);
            }
        }
    }
}

.chart {
    .dashboards-chart-bg();
    height: 550px;
    .top {
        width: 100%;
        height: @TOP_LAYER_HEIGHT;
        position: relative;
        border-radius: 32px 32px 0px 0px;
        background: linear-gradient(to top,rgba(255,255,255,0.1),rgba(255, 255, 255, 0.1));
        display: flex;
        justify-content: center;
        .weekday{
            font-family: "LG SmartFont Bold";
            font-size: 16px;
            line-height: @TOP_LAYER_HEIGHT;
            width: 110px;
            text-align: center;
            &:first-child{
                padding-left: 49px;
            }
            &:last-child{
                padding-right: 49px;
            }
        }
        .year_top_text{
            font-family: "LG SmartFont SemiBold";
            font-size: 18px;
            color: white;
            line-height: 50px;
        }
    }
    .body {
        width: 100%;
        height: calc(100% - @TOP_LAYER_HEIGHT - @BOTTOM_LAYER_HEIGHT);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .weeklyView{
            display: flex;
            justify-content: center;
            .datalayer{
                .iconImg{
                    width: 94px;
                    height: 94px;
                    margin: 0px;
                    background-size: contain;
                    margin-left: 10px;
                    margin-right: 6px;
                }
                .date{
                    width: 100%;
                    text-align: center;
                    margin-top: 6px;
                    font-family: "LG SmartFont Light";
                    font-size: 18px;
                    color: rgb(255,255,255);
                    &.today{
                        font-family: "LG SmartFont Bold";
                    }
                }
            }
        }
        .montlyView{
            margin-left: 78px;
            .datalayer{
                width: 47px;
                margin-bottom: 16px;
                margin-left: 0px;
                margin-right: 63px;
                position: relative;
                display: inline-block;
                .iconImg{
                    width: 47px;
                    height: 47px;
                    margin: 0px;
                    background-size: contain;
                }
                .today{
                    position: absolute;
                    top: 42px;
                    width: 100%;
                    text-align: center;
                    font-family: "LG SmartFont Bold";
                    font-size: 14px;
                    color: rgb(213,213,213);
                }
            }
        }
        .yearlyView{
            margin-left: 50px;
            .item {
                width: 167px;
                height: 90px;
                display: inline-block;
                margin-right: 33px;
                margin-top: 20px;
                margin-bottom: 20px;
                border-radius: 10px;
                background: linear-gradient(to top, rgba(122,106,106,0.06), rgba(255, 255, 255, 0.06));
                .title{
                    width: 100%;
                    height: 17px;
                    font-family: "LG SmartFont";
                    font-size: 18px;
                    text-align: center;
                    color: rgb(167,167,167);
                }
                .datalayer{
                    width: 100%;
                    height: 36px;
                    margin-top: 24px;
                    font-family: "LG SmartFont SemiBold";
                    text-align: center;
                    color: rgb(255,255,255);
                    margin-left: 50%;
                    transform: translateX(-50%);
                    .data{
                        font-size: 48px;
                        display: inline-block;
                    }
                    .data2{
                        font-size: 30px;
                        margin-top: 3px;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .bottom {
        width: 100%;
        height: @BOTTOM_LAYER_HEIGHT;
        position: relative;
        float: bottom;
        border-radius: 0 0 32px 32px;
        background: linear-gradient(to top,rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.1));
        display: flex;
        justify-content: center;
        .item {
            margin-top: 10px;
            width: 50%;
            .title{
                width: 100%;
                height: 17px;
                font-family: "LG SmartFont";
                font-size: 18px;
                text-align: center;
                color: rgb(167,167,167);
            }
            .datalayer{
                width: 100%;
                height: 36px;
                margin-top: 28px;
                font-family: "LG SmartFont Bold";
                text-align: center;
                color: rgb(255,255,255);
                .data{
                    font-size: 60px;
                    display: inline-block;
                }
                .data2{
                    font-family: "LG SmartFont SemiBold";
                    font-size: 30px;
                    margin-left: 15px;
                    margin-right: 15px;
                    display: inline-block;
                }
                &.archiveRate{
                    margin-left: 15px;
                    .data{
                        color: rgb(178,102,206);
                    }
                }
                &.archiveDays{
                    .data{
                        color: rgb(91,114,211);
                    }
                }
            }
        }
    }
    .hide{
        opacity: 0;
    }
}
@__DEV__: true;