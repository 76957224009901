.pageMain {
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 66px;
    height: 100%;
    width: 100%;
    top: 0;
    margin-top: 108px;
    .container{
        width: 1000px;
        height: 800px;
    }
    .sampleImage {
        position: absolute;
        background-color: #fff;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: solid 10px #fff;
        border-radius: 20px;
        width: 516px;
        height: 342px;
        object-fit: contain;
    }
    .bigImage{
        width: 1100px;
    }
    .buttonPosition{
        position: absolute;
        top:72%;
        left: 40%;
        transform: translate(-50%, -50%);
    }
    .buttonText {
        font-family: 'LG SmartFont SemiBold';
        font-size: 48px;
    }
    .timerContainer{
        position: absolute;
        top:72%;
        left: 60%;
        transform: translate(-50%, -50%);
        background-color: #363636;
        border-radius:100%;
        width: 264px;
        height: 264px;
        .pointer{
            background-color: #fff;
            position: absolute;
            top:0;
            left:50%;
            transform: translateX(-50%);
            margin:0 auto;
            width: 32px;
            height: 32px;
            border-radius:100%;
            &:before{
                content: "";
                background-color: #363636;
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width:10px;
                height:10px;
                border-radius:100%;
            }
        }
        .pointerRotated{
            position: relative;
            background-color: #363636;
            border-radius:100%;
            width: 264px;
            height: 264px;
        }
        .timer {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'LG SmartFont SemiBold';
            font-size: 48px;
            color: #fff;
            width: 200px;
            height: 200px;
            text-align: center;
            line-height: 200px;
            border-radius:100% !important;
            background-color:#1a1a1a;
            font-family: "LG SmartFont SemiBold";
            text-align: center;
        }
    }
}

.blur{
    opacity: 0.1;
}
@__DEV__: true;