.itemContainer{
	position: relative;
	display: inline-block;
	width: 250px;
	height: 450px;
	margin: 0px 5px;
	margin-left: -10px;
	margin-right: 18.5px;

	.list{
		position: relative;
		display: inline-block;
		width: 240px;
		height: 250px;
		background-color: rgba(255, 255, 255, 0.16);
		border-radius: 24px;
		margin-top: 12px;
		padding: 0px 5px;
	}

	.content{
		position: relative;
		top: 13px;
		width: 225px;
		height: 128px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 24px;
		background-color: rgba(48, 24, 61, 0.281);
	}

	.addContent{
		.iconWrap{
			position: relative;
			transform: translate(-50%, 20%);
			width: 70px;
			height: 70px;
			margin-top: 0px;
			margin-left: 50%;
			vertical-align: middle;
			background-color: rgba(143, 143, 143, 0.28);
			border: 2px solid rgba(114, 111, 111, 0.292);
			border-radius: 50%;
			box-shadow: inset 0px 0px 25px rgba(202, 200, 200, 0.17);
			.icon{
				position: relative;
				transform: translate(-50%, 0%);
				margin-top: 15%;
				margin-left: 50%;
				text-align: center;
				vertical-align: middle;
			}
		}
		.text{
			text-align: center;
			margin: 20px 0 0;
			font-size: 18px;
			font-family: "LG SmartFont Light";
		}
	}

	.contentUrl{
		position: relative;
		margin-left: 6px;
	}

	.youtubelogo {
		z-index: 5;
		bottom: 120px;
		left: 25px;
		width: 42px;
		height: 29px;
		border-radius: 0px;
		border: 0px;
		background-size: contain;
		background-repeat: no-repeat;
		position:absolute;
		background-image: url("../../../assets/list/icon/icon_youtube.png");
	}

	.infoContainer{
		position: relative;
		margin-top: 30px;
		.alarm{
			position: relative;
			text-align: left;
			margin-top: 77px;
			width: 200px;
			margin-left: auto;
			margin-right: auto;
			font-size: 18px;
			font-family: "LG SmartFont Light";
			line-height: 0.5;
		}
		.title{
			width: 200px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			font-family: 'LG SmartFont SemiBold';
			font-size: 18px;
		}
		.subtitle{
			width: 200px;
			margin-left: auto;
			margin-right: auto;
			font-family:  "LG SmartFont Light";
			text-align: center;
			font-size: 18px;
			line-height: 1.5;
		}
		.goalAlarm{
			width: 200px;
			margin-left: auto;
			margin-right: auto;
			font-family: "LG SmartFont Light";
			font-size: 18px;
			line-height: 1.5;
		}
	}

	.disabledList {
		opacity: 0.5;
		background-color: rgba(255, 255, 255, 0.16);
		border: 1px solid rgb(139, 133, 151);
		pointer-events: none;
	}
	.buttonWrap{
		text-align: center;
		.edit{
			opacity: 0;
			position: relative;
			margin-top: 40px;
		}
		.time{
			opacity: 0;
			position: relative;
			margin-top: -15px;
		}
		.focusBtn {
			opacity: 1;
		}
	}
	&.focusList {
		.list{
			transform: scale(1.2);
			z-index: 1;
			background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
			&:focus{
				background: linear-gradient(50deg, rgba(124, 135, 153, 0.84), rgba(127, 137, 155, 0.84), rgba(110, 91, 112, 0.82), rgba(115, 97, 117, 0.69)) !important;
			}
		}
	}
}

.hide {
	opacity: 0;
}
@__DEV__: true;