@ROW_HEIGHT: 60px;
@ROW_TITLE_WIDTH: 260px;
@LEFT_MARGIN: 24px;
@RIGHT_MARGIN: 24px;
@CHECKBOX_ITEM_PADDING: 14px;
@CHECKBOX_ITEM_MARGIN: 18px;
@POSITION_AREA_HEIGHT: 220px;

.infopopup{
    width: 1500px !important;
    height: 740px;
    //margin-bottom: 120px !important;
	border-radius: 20px;
	border: 2px solid rgba(212, 214, 221, 0.753);
	background: linear-gradient(to right, rgba(8,59,100,0.47), rgba(5,49,100,0.46))!important;
	background-size: cover !important;
	opacity: 1;
	background-image: url(../../../../assets/list/popup_bg.png) !important;

	>div >div >div{
		width: 100% !important;
	} //임시
}
.titlelayer{
	margin-top: 5px;
	margin-bottom: 12px;
	.backBtn{
		display: inline-block;
	}
	.title{
		width: 800px;
		display: inline-block;
		white-space: nowrap;
		font-weight: bold;
		font-size: 58px;
		line-height: 70px;
		font-weight: bold;
		letter-spacing: -1.85px;
		text-align: left;
		color: rgb(255,255,255);
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
	}
}

.row{
	position: relative;
	display: inline-flex;
	width: ~"calc(100% - @{LEFT_MARGIN})";
	height: @ROW_HEIGHT;
	line-height: @ROW_HEIGHT;
	color: rgb(255, 255, 255);
	font-family: "LG SmartFont";
	vertical-align: middle;
	margin-left: @LEFT_MARGIN;
	margin-bottom: 11px;

	.title{
		display: inline-block;
		width: @ROW_TITLE_WIDTH;
		height: 100%;
		font-size: 28px;
		text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.35);
	}
	.item{
		display: inline-block;
		width: auto;
		height: 100%;
		font-size: 23px;
		text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
	}
	.accessSwitch {
		position: relative;
		width: 200px;
		height: @ROW_HEIGHT;
		border-radius: 26px;
		margin: 0px;
		margin-left: -30px;
		font-size: 20px;
		text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
		&:focus{
		  > div:first-child{
			background-size: 100% @ROW_HEIGHT !important;
		  }
		}
	}
	.cameraArea {
		position: relative;
		display: inline-flex;
		width:  ~"calc(100% - @{ROW_TITLE_WIDTH} - @{RIGHT_MARGIN})";
		height: @ROW_HEIGHT;
		border-radius: 10px;
		padding: 10px 0px;
		//box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.3);
		border: 3px solid rgba(81, 79, 81, 0.36);
		background-color: rgba(71, 69, 71, 0.34)
	}
	.simpleCheckBox{
		width: ~"calc(100% / 4 - @{CHECKBOX_ITEM_MARGIN} * 2)" !important;
		height: 100%;
		color: rgba(255, 255, 255, 0.801);
		padding-left: @CHECKBOX_ITEM_PADDING;
		padding-right: @CHECKBOX_ITEM_PADDING;
		margin-left: @CHECKBOX_ITEM_MARGIN;
		margin-right: @CHECKBOX_ITEM_MARGIN;
		font-size: 20px;
		font-family: "LG SmartFont Light" !important;
		background: transparent;
		> div:nth-child(2){ 
			border-radius: 25px;
			background-color: rgba(92, 92, 92, 0.441) !important;
			>div{
				color: rgba(119, 119, 119, 0.445) !important;
			}
		}
		&:focus{
			color:rgb(250, 250, 250);
			>div:first-child{
				//background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
				background-color: transparent !important;
				border-radius: 15px;
				border: 2px solid rgba(255, 255, 255, 0.566);
				background: linear-gradient(50deg, rgba(90, 111, 146, 0.212), rgba(90, 111, 146, 0.212), rgba(112, 77, 116, 0.212), rgba(112, 77, 116, 0.212));
			}
			> div:nth-child(3)>div:nth-child(1){
				color:white;
			}
		}
	}
	&.size{
		height: auto;
	}
	&.position{
		height: auto;
		.cameraArea {
			height: @POSITION_AREA_HEIGHT;
		}
	}

	.cmBox {
		position: relative;
		display: inline-block;
		width: ~"calc(100% / 4 - @{CHECKBOX_ITEM_MARGIN} * 2)" !important;
		height: 100%;
		margin: 0;
		margin-left: @CHECKBOX_ITEM_MARGIN;
		margin-right: @CHECKBOX_ITEM_MARGIN;
		.checkBox{
			@TOPPADDING: 20px;
			width: 100% !important;
			height: 100%;
			color: rgba(255, 255, 255, 0.801);
			padding-bottom: ~"calc(100% - 60px - @{TOPPADDING} + 5px)";
			padding-top: @TOPPADDING;
			padding-left: @CHECKBOX_ITEM_PADDING;
			padding-right: @CHECKBOX_ITEM_PADDING;
			margin: 0;
			line-height: 2.67;
			font-size: 20px;
			font-family: "LG SmartFont Light" !important;
			background: transparent;
			> div:nth-child(2){ 
				border-radius: 25px;
				background-color: rgba(92, 92, 92, 0.441) !important;
				>div{
					color: rgba(119, 119, 119, 0.445) !important;
				}
			}
			&:focus{
				color:rgb(250, 250, 250);
				>div:first-child{
					border-radius: 15px;
					border: 2px solid rgba(255, 255, 255, 0.566);
					background: linear-gradient(50deg, rgba(90, 111, 146, 0.212), rgba(90, 111, 146, 0.212), rgba(112, 77, 116, 0.212), rgba(112, 77, 116, 0.212));
				}
				> div:nth-child(3)>div:nth-child(1){
					color:white;
				}
			}
		}
		.bgBox{
			position: absolute;
			@BOXTOP: 46px;
			@BOXMARGIN: 10px;
			@BOXBORDER: 3px;
			top: @BOXTOP;
			margin: @BOXMARGIN;
			width:  ~"calc(100% - @{BOXMARGIN} * 2 - @{BOXBORDER} * 2)";
			height: ~"calc(100% - @{BOXTOP} - @{BOXMARGIN} *2 - @{BOXBORDER})";
			border-radius: @BOXBORDER*3;
			border: @BOXBORDER solid rgba(81, 79, 81, 0.3);
			box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.3);
			background:  rgba(52, 52, 61, 0.668);
		}
		.miniBox{
			position: absolute;
			display: inline-block;
			width: 30%;
			height: 30%;
			border-radius: 4px;
			border: 2px solid rgba(141, 139, 141, 0.3);
			background-color: rgba(137, 137, 155, 0.588)
		}
		.subboxTopLeft {
			left: 2%;
			top: 2%;
		}
		.subboxBottomLeft {
			left: 2%;
			bottom: 2%;
		}
		.subboxTopRight {
			right: 2%;
			top: 2%;
		}
		.subboxBottomRight {
			right: 2%;
			bottom: 2%;
		}
	}
}
.buttonlayer{
	text-align: center;
	margin-top: 20px;
}
@__DEV__: true;