.panel {
	.headContainer{
		z-index: 40;
		position: relative;
		display: inline-flex;
		width: 100%;
		.backBtn {
			position: relative;
			margin-left: 73px;
			margin-top: 43px;
		}
		.inputBox {
			position: relative;
			margin-top: 50px;
			margin-left: 495px;
			width: 618px;
			height: 62px;
			> input {
				font-size: 22px;
				font-family: 'LG SmartFont SemiBold';
			}
		}
	}

	.toggleContainer {
		position: relative;
		z-index: 50;
		display: flex;
		margin-left: 620px;
		> div{
			font-size: 23px;
		}
	}

	.moreInfo{
		width: 1920px;
		justify-content: center;
		display: flex;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}


	.mediaListTitleWrap{
		width: auto;
		position: absolute;
		top: 320px;
	}

	.goalmediaListTitleWrap {
		width: auto;
		position: relative;
		margin-left: 50px;
	}

	.listIcon {
		display: inline-block;
		position: relative;
		width: 36px;
		height: 20px;
		margin-left: 50px;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url("../../../assets/list/icon/icon_subtitle.png");
	}

	.mediaListTitle {
		display: inline-block;
		position: relative;
		margin-left: 10px;
		font-size: 30px;
		font-family: "LG SmartFont SemiBold";
	}

	.medialistlayer{
		width: auto;
		position: absolute;
		height: 708px;
		top: 370px;
		margin-left: 50px;
		margin-right: 30px;
	}

	.goalMedialistlayer{
		width: auto;
		position: relative;
		height: 960px;
		margin-left: 50px;
		margin-right: 30px;
	}

	.noneResult {
		position: relative;
		width: 1820px;
		margin-top: 200px;
		text-align: center;
		font-size: 30px;
		line-height: 1.29;
		font-family: "LG SmartFont Light";
	}
	.planMode{
		margin-top: 400px !important;
	}

	.goallistlayer{
		margin-left: 30px;
		margin-right: 30px;
	}

	.contentContainer{
		position: relative;
	}

	.goalContentContainer{
		> div:nth-child(1) > div:nth-child(2) {
			margin-top: -10px;
		}
		> div:nth-child(2) {
			margin-top: -45px;
		}
		> div:nth-child(2) > div:nth-child(2){
			margin-top: -10px;
		}
	}

	.topBottomScrollArea{
		position:absolute;
		width: 100%;
		height: 100px;
		z-index: 21;
		&.touchGuide{
			opacity: 0.3;
			background-color: darkorange;
		}
	}

	.topScrollArea{
		transition: all 300ms ease;
		top: 310px;
		height: 73px;
	}

	.planTopScrollArea{
		top: 120px;
	}

	.bottomScrollArea{
		bottom:0px;
		height: 100px;
	}
	.dummy{
		height: 300px;
		width:100%;
	}
	.searchContainer{
		position:relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 1920px;
		height: auto;
		z-index: 50;
	}
}

@__DEV__: true;