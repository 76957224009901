@import '../../style/CommonStyle.module.less';

.fixedpanel{
	width: 514px !important;
	height: 391px !important;
	main{
		background-color: transparent !important;
	}
	.panel{
		border-radius: 25px !important;
		border-color: rgba(210, 193, 250, 0.507);
		background: linear-gradient(to bottom, @GREY_COLOR, rgba(238, 243, 250, 0.09));
		box-shadow: inset 0px 0px 60px rgba(234, 235, 236, 0.15);
		.backBtn{
			position: absolute;
			margin-top: 25px;
		}
		.text{
			width: 80%;
			margin-top: 48px;
			padding-left: 80px;
			margin-left: auto;
			margin-right: auto;
			line-height: 1.33;
			font-size: 30px;
			font-family: "LG SmartFont";
			word-break: keep-all;
			white-space: pre-wrap;
			color: white !important;
		}
		.inputWrap{
			position: relative;
			display: inline-flex;
			margin-left: auto;
			margin-right: auto;
			color: white;
			text-align: center;
			.inputField{
				position: relative;
				margin-top: 39px;
				width: 78px;
				height: 78px;
				background-color: transparent;
				color: white;
				text-align: center;
				> div:first-child {
					border-radius: 15px !important;
					border: 2px solid rgba(226, 224, 224, 0.795);
					background: linear-gradient(to bottom, rgba(238, 234, 243, 0.77), rgba(238, 243, 250, 0.25)) !important;
					box-shadow: inset 0px 0px 60px rgba(234, 235, 236, 0.15);
					color:white !important;
				}
				> input:nth-child(3) {
					font-size: 44px;
					font-family: "LG SmartFont";
					color:white !important;
				}
				&:focus{
					color: white !important;
				}
			}
		}
		.repetCount{
			position: relative;
			display: inline-flex;
			margin-top: 42px;
			margin-left: auto;
			margin-right: auto;
			.repeatNumber{
				width: 65px !important;
				height: 65px !important;
				background-color: transparent;
				color: black;
				> div:first-child{
					border-radius: 15px !important;
					background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
				}
				> div:nth-child(2){
					color: white;
					font-size: 36px;
					font-family: "LG SmartFont";
					width: 33px !important;
				}
				&:focus{
					color: white
				}
			}
		}
	}
}
@__DEV__: true;