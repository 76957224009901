@WIDTH: 610px;
@HEIGHT: 340px;

.inputPopup{
	width: @WIDTH !important;
	height: @HEIGHT;
	border-radius: 20px;
	border: 2px solid rgba(212, 214, 221, 0.753);
	background: linear-gradient(to bottom, rgba(243, 243, 243, 0.66), rgba(212, 212, 212, 0.65), rgba(177, 176, 185, 0.76), rgba(166, 165, 173, 0.51), rgba(153, 153, 160, 0.4)) !important;
	background-size: cover !important;
	opacity: 1;
	margin-bottom: calc(50vh - @HEIGHT/2) !important;
	>div >div >div{
		width: 100% !important;
	} //임시
}
.infoLayer{
  display: flex;
  align-items: center;
	margin-top: 70px;
	.title{
		font-family: "LG SmartFont";
		font-size: 37px;
		width: 200px;
		text-align: center;
		color: #1a1a1a;
	}
	.inputField{
		width: 236px;
		>div:first-child{
			// background-color:darkgrey;
		}
	}
	.tail{
		font-family: "LG SmartFont";
		width: 200px;
		text-align: left;
		font-size: 37px;
		color: #1a1a1a;
	}
}
.buttonlayer{
	text-align: center;
	margin-top: 60px;
	>div {
		vertical-align: top;
	}
}

@__DEV__: true;