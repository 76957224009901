@import '../../../style/CommonStyle.module.less';

@ITEM_WIDTH: 600px;
.layer {
    width: 100%;
    height: @dashboard-toplayer-height;
    background: linear-gradient(to top,rgba(56,55,56,0.15),rgba(10, 10, 10, 0.027));
    display: flex;
    justify-content: space-between;
    .title{
        margin-left:87px;
        width: @ITEM_WIDTH - 87px;
        display: inline-flex;
        >div {
            font-size: 48px;
            color: rgb(255,255,255);
            line-height: @dashboard-toplayer-height;
        }
        div:first-child{
            font-family: "LG SmartFont Bold";
            margin-right: 12px;
        }
        div:last-child{
            font-family: "LG SmartFont Light";
            color: rgb(167,167,167);
        }
    }
    .datePicker{
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .textLayer{
            width: 350px;
            margin-top: 16px;
            position: relative;
            overflow: hidden;
            .text{
                text-align: center;
                position: absolute;
                width: 100%;
            }
            .text:first-child{
                transform: translateX(-100%);
            }
            .text:last-child{
                transform: translateX(100%);
            }
            .text.inc{
                transition: all 300ms ease;
                transform: translateX(-100%);
                &:first-child{
                    transform: translateX(-200%);
                }
                &:last-child{
                    transform: translateX(0%);
                }
            }
            .text.dec{
                transition: all 300ms ease;
                transform: translateX(100%);
                &:first-child{
                    transform: translateX(0%);
                }
                &:last-child{
                    transform: translateX(200%);
                }
            }
        }
        .button{
            color: white !important;
            >div:first-child{
                background-color: rgba(143,143,143,0.28);
                border: 2px solid rgba(114, 111, 111, 0.292);
                border-radius: 20px;
                box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
                filter: initial !important;
                background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6));
            }
        }
    }
    .modePicker{
        margin-right: 110px;
        width: @ITEM_WIDTH - 110px;
        text-align: end;
        >div{
            margin-top: 18px;
        }
        .listplan{
            // border-radius: 17px;
            // height: 57px;
            > div > div:first-child {
                color: white ; //font
                border-radius: 25px;
                > div:first-child {
                    background-color: rgba(143,143,143,0.28);
                    border: 2px solid rgba(114, 111, 111, 0.292);
                    border-radius: 25px;
                    box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
                    filter: initial !important;
                }
                &:focus{
                    > div:first-child {
                        border-radius: 25px;
                        background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;
                    }
                }
            }
        }
    }
}

[id=floatLayer]{ //dropbox
    >div>[id]{
        >div:nth-child(2)>div:nth-child(1){
            background: linear-gradient(180deg, rgba(192,192,192,0.14), rgba(196,196,196,0.42), rgba(170,170,170,0.42));
            //background-color: rgba(250, 250, 250, 0.2) !important;
            box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
            border: 2px solid rgba(114, 111, 111, 0.292);
            margin-top: 2px;
            padding: 15px 0px 0px 0px;
            border-radius: 22px 22px 22px 22px;
        }
        >div:nth-child(2)>div:nth-child(1)>div>div>div>div>div>div:nth-child(1){ // focus
            background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;;
        }
        >div:nth-child(2)>div:nth-child(1)>div>div>div>div>div:nth-child(1){ //font
            color: white !important;
            font-size: 29px !important;
            margin: 0px !important;
        }
    }
}
@__DEV__: true;