@import '../../style/CommonStyle.module.less';
@tablebutton_focus_size: 1.04;
@tablebutton_press_size: 1.04;
.buttonContainer {
    display: inline-block;
    width: 150px;
    height: 180px;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    opacity: 0.5;
    // transition: transform 200ms ease-out;
    .image{
        width: 94px;
        height: 94px;
        margin-top: 15px;
        margin-left: 30px;
        transform: translateX(0%);
        background-size: contain;
        // transition: all 200ms ease-in-out;
    }
    &.tooMany{
        margin-left: 15px;
        margin-right: 15px;
    }
    &.selected {
        opacity: 1;
    }
    &:focus{
    //     width: 150px;
    //     height: 180px;
    //     padding-top: 0px;
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     transform: translate(0% 0%);
    //     background-image: url("../../../assets/list/focus_bar.png");
        opacity: 1;
    //     // transform: scale(@tablebutton_focus_size);
    }
    &.pressed {
        // transform: scale(@tablebutton_press_size);
    }
    .name {
        height: 16px;
        margin-top: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.38;
        letter-spacing: -0.0642rem;
        text-align: center;
        color: rgb(255, 255, 255);
    }
}
.big{
    width: 316px;
    height: 500px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 13px;
    margin-bottom: 13px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.18);
    opacity: 1;
    .image{
        position: absolute;
        top: 45px;
        width: 316px;
        height: 358px;
        margin: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-size: contain;
    }
    .name{
        font-family: "LG SmartFont Light";
        letter-spacing: normal;
        font-size: 30px;
        line-height: 30px;
        bottom: 33px;
    }
    &:focus{
        width: 333px;
        height: 526px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 6px;
        margin-right: 7px;
        background: linear-gradient(to top, rgba(74, 104, 148, 0.69), rgba(74, 104, 148, 0.69), rgba(109, 59, 117, 0.69), rgba(109, 59, 117, 0.69));
        .name{
            font-family: "LG SmartFont Bold";
            font-size: 36px;
            bottom: 46px;
        }
    }
}
@__DEV__: true;