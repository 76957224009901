.main {
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;

    .pageTitle{
        position:absolute;
        width: 100%;
        text-align: center;
        top: 170px;
        font-family: "LG SmartFont Bold";
        font-size: 60px;
        line-height: 60px;
    }
    .subTitle{
        position:absolute;
        width: 100%;
        text-align: center;
        top: 265px;
        font-family: "LG SmartFont SemiBold";
        font-size: 40px;
        line-height: 40px;
    }
    .submit-btn-container {
        text-align: center;
        position: absolute;
        display: flex;
        // gap: 250px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 80px;
        >div {
                vertical-align: top;
        }
    }
}
.blur{
    opacity: 0.1;
}

.card{
    position:absolute;
    border-radius: 30px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 480px;
    display: flex;
    flex-flow: row;
    align-items: center;
    color: #1a1a1a;
    font-family: "LG SmartFont SemiBold";
    background-image: url("../../../../assets/submitPageBackIcon.png");
    border: 1px solid white;
    .cardLeft{
        position: relative;
        width: 440px;
        height: 480px;
        .cardImage{
            background-color: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 16px solid #fff;
            border-radius: 100%;
            width: 213px;
            height: 223px;
            padding: 20px;
        }
    }
    .cardRight{
        width: 410px;
        height: 478px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        gap:34px;
        margin-left: -2px;
        .cardItem{
            .cardItemTitle{
                position: relative;
                margin-bottom: 10px;
                padding-left: 10px;
                &:before{
                    content: "";
                    position: absolute;
                    left: -5px;
                    top: 11.5px;
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background-color: black;
                }
            }
            .cardItemContent{
                padding: 0 15px 15px 30px;
                font-size: 40px;
                line-height: 45px;
                font-family: "LG SmartFont Bold";
            }
        }
    }
}
@__DEV__: true;