@import '../../style/CommonStyle.module.less';

@LEFT_MARGIN: 82px;
@CENTER_MARGIN: 24px;
@CHART_MARGIN: 43px;
.bodyarea{
    .mainpanel-bodyarea();
    .toplayer{
        margin-bottom: 40px
    }
    .scroller{
        margin-left: @LEFT_MARGIN;
        width: 1920px - @LEFT_MARGIN;
        height: ~"calc(100% - @{dashboard-toplayer-height} - 40px)";
    }
    .chartLayer{
        display:inline-block;
        vertical-align: top;
        margin-right: @CENTER_MARGIN;
        margin-bottom: @CHART_MARGIN;
        position: relative;
    }
    .historyLayer{
        display:inline-block;
        vertical-align: top;
        padding-bottom: 40px;
        position: relative;
    }
    .prePareImg{
        width: 100%;
        height: 100%;
        background-size: cover;
        margin: 0px;
    }
}
.notLogin{
    .mainpanel-bodyarea();
    background: linear-gradient(180deg, rgba(8,8,8,0.11), rgba(0,0,0,0.71), rgba(20,19,20,0.71), rgba(18,17,19,0.71)); 
    .title{
        position: absolute;
        font-family: "LG SmartFont SemiBold";
        text-align: center;
        top: 379px;
        width: 100%;
        font-size: 72px;
    }
    .subtitle{
        position: absolute;
        font-family: "LG SmartFont";
        text-align: center;
        top: 484px;
        width: 100%;
        font-size: 36px;
    }
    .button{
        position: absolute;
        top: 590px;
        left: 50%;
        transform: translateX(-50%);
    }
}
@__DEV__: true;