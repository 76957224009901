.videoMain {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	right: 0;
	background-color: black;
	video {
		width: 100%;
		height: 100%;
		background-color: transparent;
		object-fit: fill;
		position: absolute;
	}
}
.playLogBg{
	width: 100%;
	height: 100%;
	position: absolute;
	margin: 0;
	transition: opacity 500ms ease-in-out;
	&.hide {
		opacity: 0;
	}
}
.hide {
	visibility: hidden;
}
.localGuide{
	position: absolute;
	top: 50px;
	color: red;
	left: 50%;
	transform: translateX(-50%);
}

.cameraSettingsButtonContainer {
	position: absolute;
	top: 150px;
	left: 246px;
	z-index: 1;
}
@__DEV__: true;