@import '../../style/CommonStyle.module.less';

.header {
    display: flex;
    margin: 0 0 9px 0;
    .categorybanner{
        position: relative;
        left: 1920px;
        height: @bannerSmall;
        .preview{
            position: absolute;
            top:  ~"calc(-@{toplayerHeight})";
            width: 856px;
            height: 480px;
            margin: 0px;
            background-size: cover;
            right: 0px;
            z-index:-1;
        }
        .poster{
            transition: opacity 1000ms ease-in-out;
        }
        .previewMask{
            width: 857px;
            height: 480px;
            top:  ~"calc(-@{toplayerHeight})";
            // background-image: url(../../../assets/banner/violet_videocover_small.png);
        }
    }
    .icon{
        width: 140px;
        height: 140px;
        margin: 50px 0px 9px 133px;
    }
    .textbox {
        width:1600px;
        .content{
            display: flex;
            justify-content: space-between;
            .title{
                width: 570px;
                height: 39px;
                margin: 58px 10px 0px 32px;
                font-family: "LG SmartFont Bold";
                font-size: 50px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: rgb(255, 255, 255);
            }
            .unselectedCatGroup{
                .thumbs {
                    width: 45px;
                    height: 45px;
                    margin: 48px 59px 0 0px;
                    background-size: contain;
                    opacity: 0.5;
                }
             }
        }
        .intro{
            width: 1525px;
            height: 136px; //line height * line-clamp
            margin: 15px 0px 0px 30px;
            font-family: "LG SmartFont";
            font-size: 30px;
            line-height: 34px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.7px;
            //text-align: left;
            text-align: justify;
            text-justify: inter-word;
            white-space: pre-wrap;
            color: rgba(255, 255, 255, 0.75);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            &.en{
                width: 1550px;
            }
        }
    }
}
@__DEV__: true;