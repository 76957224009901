.customimage{
    &.hidden {
        opacity: 0;
    }
    &:not(.hidden){
        transition: opacity 500ms ease-in-out;
    }
}
.imgWrap{
    height: 100%;
}

@__DEV__: true;