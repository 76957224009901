@import '../../../style/CommonStyle.module.less';
.pagemain {
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    .container{
        width: 772px;
        display: flex;
        align-items: center;
        margin-top: 35px;
        margin-bottom: 35px;
    }
    .menuName{
        width: 200px;
        text-align: center;
        font-family: "LG SmartFont Bold";
        font-size: 39px;
        color: #ffffff;
    }
    .radio{
        width: 250px;
        font-size: 34px;
        margin-bottom: 0;
    }
    .inputbtn{
        width: 362px;
        height: 89px;
        font-size: 34px;
        font-family: "LG SmartFont";
        background-color: #55484e;
        margin-left: 30px;
        line-height: 89px;
        text-align: center;
        border-radius: 19px;
        &:focus{
            background: @FOCUS_COLOR;
        }
    }
    .tailstr{
        margin-left: 36px;
        font-size: 39px;
        font-family: "LG SmartFont";
    }
}
.blur{
    opacity: 0.1;
}
@__DEV__: true;