@import '../../../style/CommonStyle.module.less';
.panel {
    margin-top: 25px;
    .titleArea {
        position: relative;
        display: block;
        .titleBox {
            position: relative;
            display: inline-block;
            width: 300px;
            height: 35px;
            left: 75px;
            padding: 10px 20px;
            top: 0px;
            .text {
              position: relative;
              color: rgb(252, 252, 252);
              top: 0px;
              font-size: 28px;
              text-align: start;
              margin-top: 13px;
              font-family: "LG SmartFont";
            }
        }
        .textArea {
            position: relative;
            display: inline-block;
            width: 1000px;
            height: 35px;
            left: 60px;
            padding: 10px 15px;
            background-color: rgba(174, 0, 255, 0.0);
            .text {
              position: relative;
              color: rgb(252, 252, 252);
              font-size: 23px;
              text-align: start;
              margin-top: 13px;
              font-family: "LG SmartFont";
            }
        }
        .title {
            position: relative;
            width:100%;
            text-align: center;
            font-size: 36px;
            font-family:'LG SmartFont Bold';
        }
        .alarm {
          position: relative;
          display: inline-flex;
          width: 100%;
          .alarmTitle {
            position: relative;
            height: 72px;
            margin-left: 93px;
            margin-top: 40px;
            font-size: 30px;
            font-family: "LG SmartFont";
          }
          .alarmSwitch {
            position: relative;
            width: 200px;
            height: 72px;
            border-radius: 26px;
            margin-left: 150px;
            margin-top: 20px;
            font-size: 20px;
            font-family: "LG SmartFont";
            &:focus{
              > div:first-child{
                background-size: 100% 72px !important;
              }
            }
          }
        }
    }
    .cmArea {
        .ScreenTitle {
            position: relative;
            display: inline-flex;
            width: 300px;
            height: 45px;
            left: 74px;
            padding: 10px 20px;
            bottom: 185px;
            .text {
                position: relative;
              color: rgb(252, 252, 252);
              top: 0px;
              font-size: 28px;
              text-align: start;
              margin-top: 13px;
              font-family: "LG SmartFont";
            }
        }
        .cameraArea {
            position: relative;
            display: inline-block;
            width: 1290px;
            height: 255px;
            left: 75px;
            border-radius: 10px;
            margin-bottom: 15px;
            padding: 15px 0px;
            box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.3);
            border: 3px solid rgba(81, 79, 81, 0.3);
            background-color: rgba(71, 69, 71, 0.264)
        }
          .checkBox{
            width: 312px !important;
            height: 251px;
            position: absolute;
            top: -58px;
            left: -11px;
            color: rgba(255, 255, 255, 0.801);
            padding-left: 13px;
            padding-bottom: 196px;
            margin-left: 0px;
            line-height: 2.67;
            font-size: 20px;
            font-family: "LG SmartFont Light" !important;
            background: transparent;
            &:focus{
                color:rgb(250, 250, 250);
                >div:first-child{
                    border-radius: 15px;
                    border: 3px solid rgba(255, 255, 255, 0.466);
                    background: linear-gradient(50deg, #6e809e33, #5a6f9233, #704d7433, #704d7433);
                }
                > div:nth-child(3)>div:nth-child(1){
                    color:white
                }
            }
          }
          .cmBox {
              position: relative;
              display: inline-block;
              width: 250px;
              height: 125px;
              top: 45px;
              left: 0px;
              margin: 15px 13px;
              border-radius: 7px;
              border: 3px solid rgba(81, 79, 81, 0.3);
              padding: 30px 20px;
              box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.3);
              background:  rgba(52, 52, 61, 0.668);
  
              .subboxSizeBig {
                  position: absolute;
                  display: inline-block;
                  width: 93px;
                  height: 53px;
                  right: 5px;
                  top: 5px;
                  border-radius: 4px;
                  border: 2px solid rgba(141, 139, 141, 0.3);
                  background-color: rgba(89, 89, 107, 0.588)
              }
              .subboxSizeMedium {
                  position: absolute;
                  display: inline-block;
                  width: 73px;
                  height: 41px;
                  right: 5px;
                  top: 5px;
                  border-radius: 4px;
                  border: 2px solid rgba(141, 139, 141, 0.3);
                  background-color: rgba(89, 89, 107, 0.588)
              }
              .subboxSizeSmall {
                  position: absolute;
                  display: inline-block;
                  width: 61px;
                  height: 35px;
                  right: 5px;
                  top: 5px;
                  border-radius: 4px;
                  border: 2px solid rgba(141, 139, 141, 0.3);
                  background-color: rgba(89, 89, 107, 0.588)
              }
              .subboxTopLeft {
                  position: absolute;
                  display: inline-block;
                  width: 83px;
                  height: 48px;
                  left: 5px;
                  top: 5px;
                  border-radius: 4px;
                  border: 2px solid rgba(141, 139, 141, 0.3);
                  background-color: rgba(89, 89, 107, 0.588)
              }
              .subboxBottomLeft {
                  position: absolute;
                  display: inline-block;
                  width: 83px;
                  height: 48px;
                  left: 5px;
                  bottom: 5px;
                  border-radius: 4px;
                  border: 2px solid rgba(141, 139, 141, 0.3);
                  background-color: rgba(89, 89, 107, 0.588)
              }
              .subboxTopRight {
                  position: absolute;
                  display: inline-block;
                  width: 83px;
                  height: 48px;
                  right: 5px;
                  top: 5px;
                  border-radius: 4px;
                  border: 2px solid rgba(141, 139, 141, 0.3);
                  background-color: rgba(89, 89, 107, 0.588)
              }
              .subboxBottomRight {
                  position: absolute;
                  display: inline-block;
                  width: 83px;
                  height: 48px;
                  right: 5px;
                  bottom: 5px;
                  border-radius: 4px;
                  border: 2px solid rgba(141, 139, 141, 0.3);
                  background-color: rgba(89, 89, 107, 0.588)
              }
          }

    }

  .buttonContainer{
    position: relative;
    margin-top: 37px;
    text-align: center;
    .doneBtn {
      position: relative;
    }
    .disabledBtn {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}
@__DEV__: true;