.infoContainer{
	padding: 0px 10px;
	.iconWrap{
		position: relative;
		transform: translate(-50%, 50%);
		width: 120px;
		height: 120px;
		margin-top: 0px;
		margin-left: 50%;
		vertical-align: middle;
		background-color: rgba(143, 143, 143, 0.28);
		border: 2px solid rgba(114, 111, 111, 0.292);
		border-radius: 50%;
		box-shadow: inset 0px 0px 25px rgba(202, 200, 200, 0.17);
		.icon{
			position: relative;
			transform: translate(-50%, 0%);
			margin-top: 15%;
			margin-left: 50%;
			text-align: center;
			vertical-align: middle;
		}
	}

	.addText{
		position: relative;
		text-align: center;
		font-size: 28px;
		margin-top: 80px;
		font-family: "LG SmartFont Light";
	}

	.goalList {
		display:flex;
		position: relative;
		margin-left: auto;
		margin-right: auto;
	}

	.editText {
		opacity: 0;
		position: relative;
		margin-top: 15px;
		text-align: center;
		font-family: 'LG SmartFont SemiBold';
		font-size: 18px;
	}
}

@__DEV__: true;