.infoContainer{
	.time {
		position: relative;
		display:inline-block;
		left: 40px;
		margin-top: 48px;
		font-size: 60px;
		font-family: "LG SmartFont SemiBold";
	}

	.day{
		position: relative;
		display: inline-block;
		width: 250px;
		text-align: right;
		left: 120px;
		font-size: 30px;
		font-family: "LG SmartFont Light";
	}

	.text{
		opacity: 0;
		font-size: 20px;
		margin-top: 20px;
		text-align: center;
		font-family: "LG SmartFont Light";
	}

	.iconWrap{
		position: relative;
		transform: translate(-50%, 20%);
		width: 80px;
		height: 80px;
		margin-top: 0px;
		margin-left: 50%;
		vertical-align: middle;
		background-color: rgba(143, 143, 143, 0.28);
		border: 2px solid rgba(114, 111, 111, 0.292);
		border-radius: 50%;
		box-shadow: inset 0px 0px 25px rgba(202, 200, 200, 0.17);
		.icon{
			position: relative;
			transform: translate(-50%, -5%);
			margin-top: 15%;
			margin-left: 50%;
			text-align: center;
			vertical-align: middle;
		}
	}

	.addtext{
		text-align: center;
		font-size: 28px;
		margin-top: 25px;
		font-family: "LG SmartFont Light";
	}
}
@__DEV__: true;