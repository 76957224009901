@import '../../style/CommonStyle.module.less';

.panel {
    position: relative;
    font-family: 'LG SmartFont';
    .mainTitle{
        text-align: center;
        margin-top: 50px;
        font-size: 60px;
        font-weight: bold;
    }
    .btnContainer{
        display: flex;
        margin: 0 130px;
        text-align: center;
        .btnStyle{
            position: relative;
            margin: 180px auto;
            .title{
                margin: 55px auto;
                font-size: 40px;
                color: #2f262b;
                font-weight: bold;
                line-height: 40px;
            }
            .basicInfo{
                margin-bottom: 55px;
            }
            .iconStyle{
                position:absolute;
                top: 100px;
                margin: 50px auto;
            }
            .detail{
                position: absolute;
                top: 322px;
                left: 5px;
                right: 5px;
                line-height: 44px;
                color: rgba(47, 38, 43, 0.84);
                font-size: 40px;
                font-weight: bold;
            }
            .infoBox{
                display: flex;
                text-align: left;
                margin: 0 60px;
                font-size: 30px;
                color: #454545;
                .qBox{
                    width: 190px;
                    padding: 10px 0;
                }
                .aBox{
                    width: 160px;
                    padding: 10px 0;
                }
            }
            .icon{
                width: 156px;
                height: 131px;
                position:absolute;
                top: 156px;
                left: 105px;
            }
        }
    }
}

@__DEV__: true;