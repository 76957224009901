.titleContainer{
    margin-bottom: 8px;
    height: 40px;
    .dot{
        width: 8px;
        height: 8px;
        margin-bottom: 3px;
        margin-right: 10px;
        display: inline-block;
        border-width: 3px;
        border-color: rgb(245,176,27);
        border-style: solid;
        border-radius: 6px;
    }
    .title{
        height: 27px;
        font-family: LG SmartFont SemiBold;
        font-size: 28px;
        text-align: left;
        color: rgb(255,255,255);
        display: inline-block;
    }
}
@__DEV__: true;