@toplayerHeight: 120px;
@bannerBig: 864px;
@bannerMedium: 532px;
@bannerSmall: 198px;
@dashboard-toplayer-height: 90px;
@button-focus-size: 1.02;
@button-press-size: 1.04;
@panel_header_height: 123px;
@FOCUS_COLOR: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6));
@GREY_COLOR: rgba(110, 110, 116, 0.9);
@TOOLTIP_BG_COLOR: rgba(255, 247, 209, 0.9);

.mainpanel-bodyarea() {
	position: absolute;
	top: @toplayerHeight;
	width: 100%;
	height: ~"calc(100% - @{toplayerHeight})";
 }
.dashboards-chart-bg() {
	position: relative;
	width: 866px;
	height: 380px;
	border-radius: 32px;
	box-shadow: inset 0px -10px 32px 0 rgba(255, 255, 255, 0.1), inset 0px 40px 100px 0 rgba(0, 0, 0, 0.15);
	border-style: solid ;
	border-width: 2.9px 0.5px 0.5px 0.5px;
	border-color: rgba(87, 87, 90, 0.356);
	border-image-slice: 1;
	background: linear-gradient(to bottom, rgba(111,108,116,0.31), rgba(81,85,90,0.28));
	background-origin: border-box;
	background-clip: content-box, border-box;
 }
.root{
	overflow: hidden;
}

 /* Hangul */
@font-face {
	font-family: "LG SmartFont";
	src: url('../../assets/fonts/LGSmHaR.woff2') format("woff2");
	// src: url('../../assets/fonts/LGSmHaR.ttf') format("truetype");
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
@font-face {
	font-family: "LG SmartFont Bold";
	src: url('../../assets/fonts/LGSmHaB.woff2') format("woff2");
	// src: url('../../assets/fonts/LGSmHaB.ttf') format("truetype");
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
@font-face {
	font-family: "LG SmartFont Light";
	src: url('../../assets/fonts/LGSmHaL.woff2') format("woff2");
	// src: url('../../assets/fonts/LGSmHaL.ttf') format("truetype");
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
@font-face {
	font-family: "LG SmartFont SemiBold";
	src: url('../../assets/fonts/LGSmHaSB.woff2') format("woff2");
	// src: url('../../assets/fonts/LGSmHaSB.ttf') format("truetype");
	unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}
// https://w3c.github.io/webvtt/#css-extensions
video::cue {
	color: rgb(255, 255, 255);
	text-shadow: -2.5px 0px 2px rgb(0, 0, 0), 0px 2.5px 2px rgb(0, 0, 0), 2.5px 0px 2px rgb(0, 0, 0), 0px -2.5px 2px rgb(0, 0, 0);
	font-family: "LG SmartFont";
	font-size: 58px;
	line-height: 1.2;
	background-color: transparent;
	visibility: hidden;
}