@import '../../style/CommonStyle.module.less';

.notLogin{
    .mainpanel-bodyarea();
    background: linear-gradient(180deg, rgba(8,8,8,0.11), rgba(0,0,0,0.71), rgba(20,19,20,0.71), rgba(18,17,19,0.71)); 
    .title{
        position: absolute;
        font-family: "LG SmartFont SemiBold";
        text-align: center;
        top: 379px;
        width: 100%;
        font-size: 72px;
    }
    .subtitle{
        position: absolute;
        font-family: "LG SmartFont";
        text-align: center;
        top: 484px;
        width: 100%;
        font-size: 36px;
    }
    .button{
        position: absolute;
        top: 590px;
        left: 50%;
        transform: translateX(-50%);
    }
}
@__DEV__: true;