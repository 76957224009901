.setpopupPanel {
    border-radius: 32px !important;
    padding: 0px;
    main{
        background-color: transparent !important;
    }
    .panel {
        height: 1000px;
        width: 570px;
        background: linear-gradient(to bottom, rgba(223, 223, 223, 0.69), rgba(209, 209, 209, 0.47), rgba(194, 194, 194, 0.32), rgba(175, 175, 175, 0.23)) !important;
        border: 2px solid rgba(136, 134, 143, 0.24);
        border-radius: 32px;
        padding: 0px;
        color: rgb(255, 255, 255);
        .title {
            position: relative;
            font-size: 28px;
            font-family: "LG SmartFont Light";
            left: 50px;
            width: 700px;
            height: 140px;
            margin-left: 23px;
            margin-top: 65px;
            margin-bottom: 0px;
        }
        .lineBar {
            position: absolute;
            display: block;
            width: 85%;
            height: 3px;
            left: 5%;
            top: -25px;
            background: linear-gradient(to left, rgba(124, 124, 124, 0.15), rgba(212, 212, 212, 0.55), rgba(212, 212, 212, 0.55), rgba(124, 124, 124, 0.15));
        }
        .subBtn {
            position: relative;
            font-size: 30px;
            left: -13px;
            top: 0px;
            font-family: "LG SmartFont Light";
            width: 570px;
            height: 85px;
            background-size: contain;
            margin: 0px;
            padding-left: 60px;
            background: transparent;
            &:focus{
                color:rgb(219, 219, 219);
                >div:first-child{
                    background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6));
                }
                > div:nth-child(3)>div:nth-child(1){
                    color:white
                }
            }
        }
        .cancelBtn {
            position: absolute;
            margin-left: 0px;
            left: -50px;
            top: 10px;
            font-family: "LG SmartFont Light";
        }
    }
}
@__DEV__: true;