@import '../../style/CommonStyle.module.less';

.bodyarea{
    .mainpanel-bodyarea();
    .categorylist {
        margin-top: 50px;
        margin-bottom: 10px;
        transition: opacity 500ms ease-in-out;
    }
    .categorydetail{
        position: absolute;
        margin-top: 0px;
        top: 0;
        z-index: -1;
    }
    .hide{
        opacity: 0;
    }
    .medialistlayer{
        width: auto;
        position:relative;
        height: 700px;
        margin-left: 60px;
        margin-right: 60px;
    }
    .dummy{
        height: 300px;
        width:100%;
    }
}
.topBottomScrollArea{
    position:absolute;
    width: 100%;
    height: 100px;
    z-index: 21;
    &.touchGuide{
        opacity: 0.3;
        background-color: darkorange;
    }
}
.topScrollArea{
    transition: all 300ms ease;
    top: @bannerSmall;
    height: 73px;
}
.bottomScrollArea{
    bottom:0px;
    height: 82px;
}
@__DEV__: true;