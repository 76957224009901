.itemContainer{
	position: relative;
	display: inline-block;
	width: 340px;
	height: 550px;
	margin-right: 30px;
	.planOrder{
		width: 340;
		text-align: center;
		font-family: "LG SmartFont Bold";
		font-size: 24px;
	}
	.planOrderFocus{
		transform: scale(1.2);
		margin-bottom: 10px;
	}
	.list{
		position: relative;
		display: inline-block;
		width: 340px;
		height: 260px;
		background-color: rgba(143, 143, 143, 0.28);
		border: 2px solid rgba(114, 111, 111, 0.292);
		box-shadow: inset 0px 0px 20px rgba(202, 200, 200, 0.15);
		border-radius: 24px;
		top: 12px;
		padding: 0px 5px;
		&:focus{
			transform: scale(1.15);
			background: linear-gradient(50deg, rgba(124, 135, 153, 0.84), rgba(127, 137, 155, 0.84), rgba(110, 91, 112, 0.82), rgba(115, 97, 117, 0.69)) !important;
		}
	}

	.content{
		position: relative;
		width: 300px;
		height: 168px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 24px;
		background-color: transparent;

		.playtime{
			position: absolute;
			display: block;
			z-index: 5;
			background-color: rgba(39, 39, 39, 0.7);
			width: auto;
			height: 30px !important;
			right: 10px;
			bottom: 5px;
			font-size: 18px;
			text-align: center;
			margin: auto;
			padding-bottom: 2px;
			padding-left: 9px;
			padding-right: 9px;
			color: rgb(255, 255, 255);
			transition: initial;
			border-radius: 18px; // old = 7%
			border: 0px;
		}

		.cover{
			position: absolute;
			width: 300px;
			height: 168px;
			margin-left: auto;
			margin-right: auto;
			border-radius: 24px;
			background-color: black;
			opacity: 0.2;
		}

		.repeatCount{
			position: relative;
			margin-top: 140px;
			font-family: "LG SmartFont";
			font-size: 18px;
			line-height: 1.5;
			text-align: center;
			color: white;
		}
	}

	.addContent{
		.iconWrap{
			position: relative;
			transform: translate(-50%, 20%);
			width: 100px;
			height: 100px;
			margin-top: 40px;
			margin-left: 50%;
			vertical-align: middle;
			background-color: rgba(143, 143, 143, 0.28);
			border: 2px solid rgba(114, 111, 111, 0.292);
			border-radius: 50%;
			box-shadow: inset 0px 0px 25px rgba(202, 200, 200, 0.17);
			.icon{
				position: relative;
				transform: translate(-50%, 0%);
				margin-top: 10%;
				margin-left: 50%;
				text-align: center;
				vertical-align: middle;
			}
		}
		.text{
			text-align: center;
			margin: 40px 0 0;
			font-size: 18px;
			font-family: "LG SmartFont Light";
		}
	}

	.contentUrl{
		width: 300px;
		height: 168px;
		margin-top: 18px;
		margin-left: 20px;
	}

	.infoContainer{
		position: relative;
		margin-top: -25px;
		.title{
			width: 250px;
			margin-left: auto;
			margin-right: auto;
			font-family: 'LG SmartFont';
			font-size: 20px;
		}
		.subtitle{
			width: 250px;
			margin-left: auto;
			margin-right: auto;
			font-family:  "LG SmartFont Light";
			font-size: 20px;
		}
	}

	.disabledList {
		opacity: 0.5;
		background-color: rgba(255, 255, 255, 0.16);
		border: 1px solid rgb(139, 133, 151);
		pointer-events: none;
	}
	.buttonWrap{
		text-align: center;
		.deleteBtn{
			opacity: 0;
			position: relative;
			margin-top: 40px;
		}
		.reorderBtn{
			opacity: 0;
			position: relative;
			margin-top: -15px;
		}
		.repeatBtn{
			opacity: 0;
			position: relative;
			margin-top: -15px;
		}
		.focusBtn {
			opacity: 1;
		}
	}

	.focusList {
		transform: scale(1.15);
		z-index: 1;
		background: linear-gradient(50deg, rgba(124, 135, 153, 0.84), rgba(127, 137, 155, 0.84), rgba(110, 91, 112, 0.82), rgba(115, 97, 117, 0.69)) !important;
	}
}

.hide {
	opacity: 0;
}
@__DEV__: true;