@import '../../style/CommonStyle.module.less';

@BUTTON_LAYER_WIDTH: 260px;
.infopopup{
	width: 1060px !important;
	border-radius: 22px;
	border: 2px solid rgba(168, 169, 172, 0.753);
	background: linear-gradient(to bottom, rgba(243, 243, 243, 0.66), rgba(212, 212, 212, 0.65), rgba(177, 176, 185, 0.76), rgba(166, 165, 173, 0.51), rgba(153, 153, 160, 0.4)) !important;
	background-size: cover !important;
	opacity: 1;
	z-index: 101;
	>div{
		height: 100%;
		>div{
			height: 100%;
			>div{
				height: 100% !important;
				width: 100% !important;
			}
		}
	}
}
.messagelayer{
	width: 100%;
	height: 100%;
	display: inline-block;
	// background-color:blue;
	position: relative;
	vertical-align: middle;
	&.noButton{
		margin-bottom: -33px;
	}
	&.hasButton{
		width: ~"calc(100% - @{BUTTON_LAYER_WIDTH})";
	}
	.message{
		position: relative;
		display: inline-block;
		font-size: 30px;
		font-family: "LG SmartFont";
		white-space: pre-wrap;
		line-height: 1.4;
		width: 100%;
		color: rgb(255, 255, 255);
		height: 100%;
		&.selected{
			>div>div:nth-child(2)>div:nth-child(2)>div:nth-child(1)>div:nth-child(2){
				box-shadow: 0 0 0 0.08333rem #30ad6b !important;
			}
		}
		>div{
			position: relative;
			:focus{
				:not(.selected){
					>div:nth-child(1)>div:nth-child(2){
						box-shadow: 0 0 0 0.08333rem rgb(76,80,89) !important;
					}
				}
			}
			>div:nth-child(2){
			position: relative;
			color: rgb(255, 255, 255);
			font-size: 24px;
			font-family: "LG SmartFont";
			font-weight: normal;
			margin: 0;
				:not(.selected){
					>div:nth-child(1)>div:nth-child(2){
						box-shadow: 0 0 0 0.08333rem rgb(255, 255, 255);
					}
				}
			}
		}
	}
	.noticeAlert {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
	}
}
.buttonlayer{
	display: inline-block;
	position: relative;
	width: @BUTTON_LAYER_WIDTH;
	height: 100%;
	margin-top: 10px;
	vertical-align: middle;
	// background-color:red;
	.buttonWrapper{
		position: relative;
	}
}

@__DEV__: true;