.fixedpanel{
	width: 720px !important;
	height: 391px !important;
	main{
		background-color: transparent !important;
	}
	.panel{
		border-radius: 25px !important;
		border-color: rgba(210, 193, 250, 0.507);
		background: linear-gradient(to bottom, rgba(224, 220, 228, 0.64), rgba(90, 92, 95, 0.47));
		box-shadow: inset 0px 0px 60px rgba(255, 255, 255, 0.342);
		.text{
			width: 680px;
			margin-top: 48px;
			margin-left: 24px;
			margin-right: auto;
			line-height: 1.33;
			font-size: 39px;
			color: white;
			font-family: "LG SmartFont";
			word-break: keep-all;
		}

		.timepicker{
			position: relative;
			display: inline-flex;
			margin-top: 72px;
			margin-left: 0 !important; //auto !important;
			width:80%

		}
		.button{
			margin-top: 160px;
			margin-left: -20px;
			position: relative;
		}
	}
}

@__DEV__: true;