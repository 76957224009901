/*infoicon 추가*/
.infoItem{
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 35px;
}
.infoIcon {
    position: relative;
    display: inline-block;
    background-size: contain;
    width: 34px;
    height: 34px;
}
.iconAvailable {
    background-image: url("../../../assets/list/icon/icon_available.png");
}
.iconCalory {
    background-image: url("../../../assets/list/icon/icon_calory.png");
}
.iconCardio {
    background-image: url("../../../assets/list/icon/icon_cardio.png");
}
.iconDuration {
    background-image: url("../../../assets/list/icon/icon_duration.png");
}
.iconFullbody {
    background-image: url("../../../assets/list/icon/icon_fullbody.png");
}
.iconMat {
    background-image: url("../../../assets/list/icon/icon_mat.png");
}
.iconStretching {
    background-image: url("../../../assets/list/icon/icon_stretching.png");
}
.iconDifficulty {
    background-image: url("../../../assets/list/icon/icon_difficulty.png");
}

.infotext {
    position:relative;
    display:inline-block;
    font-family:'LG SmartFont';
    width: 196px;
    height: 32px;
    font-size: 30px;
    font-weight: 50;
    letter-spacing: 1px;
    line-height: 34px;
    margin-left:8px;
    white-space: nowrap;
    color: white;
    // will-change: transform;
}
.medium{
    .infoItem{
        margin-right: 12px;
        margin-bottom: 30px;
    }
    .infotext {
        width: 196px;
    }
}
.small{
    &.infoIcon {
        width: 28px;
        height: 28px;
    }
    .infoItem{
        margin-bottom: 7px;
        margin-right: 14px;
    }
    .infotext {
        width: auto;
        min-width: 100px;
        max-width: 200px;
        font-size: 27px;
    }
}
.video{
    .infoItem{
        margin-bottom: 0px;
        margin-right: 2px;
    }
    .infotext {
        width: 156px;
    }
}
@__DEV__: true;