.timePicker {
	position: relative;
	div:nth-child(1) > div:nth-child(1)  {
		margin-left: 40px;
		font-family: "LG SmartFont Light" !important;
		line-height: 2;
	}
	> div:nth-child(2) > div:nth-child(2)> div:nth-child(2){
		font-size: 30px;
		font-family: "LG SmartFont Light";
	}
	:before{
		background: linear-gradient(to top, rgba(105, 148, 213, 0.75), rgba(165, 100, 175, 0.75)) !important;
	}
	span {
		color:white !important;
	}
	:focus{
		color:white !important;
	}
}
@__DEV__: true;