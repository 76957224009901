@import '../../../style/CommonStyle.module.less';

@keyframes topGroupExtend {
    from {
        margin-left: 0;
      }
     to {
        margin-left: 50%;
     }
}
@keyframes topGroupReduce {
    from {
        margin-left: 50%;
      }
     to {
        margin-left: 0;
     }
}
.top-title-layer{
    height: @toplayerHeight;
    position: absolute;
    width: 100%;
}
.topgroup{
    display: inline-block;
    white-space: nowrap;
    width: auto;
    position: absolute;
    transform: translateX(-50%);
    margin-top: 17px;
    animation-duration: 0.5s;
    -moz-animation-name: topGroupReduce;
    -webkit-animation-name: topGroupReduce;
    animation-name: topGroupReduce;
    :global(.enyo-locale-right-to-left) & {
        transform: translateX(50%);
        margin-right: 50%;
    }
    &.extended{
        margin-left: 50%;
        -moz-animation-name: topGroupExtend;
        -webkit-animation-name: topGroupExtend;
        animation-name: topGroupExtend;
    }
}
.globalbutton {
    float: right;
    margin-top: 17px;
    margin-right: 85px;
    .account {
        display: inline-block;
        margin-right: 12px;
    }
    :global(.enyo-locale-right-to-left) & {
        float: left;
        margin-right: 0px;
        margin-left: 0.5rem;
    }
    >div {
        vertical-align: middle;
    }
}
.logo{
    height: 2.5417rem;
    width: 7.75rem;
    margin-left: 3.5417rem;
    margin-top: 17px;
    background-size: contain;
    background-image: url(../../../../assets/logo.png);
    transition: opacity 500ms ease-in-out;
}
.hide {
    opacity: 0;
}
.isQAServer{
    position:absolute;
    color: red;
    top: 31px;
    font-family: "LG SmartFont Bold";
    font-size: 40px;
    left: 270px;
}
@__DEV__: true;