@import '../../../style/CommonStyle.module.less';

.chart {
    .dashboards-chart-bg();
}
.canvas {
    width: auto;
    height: auto;
}
.info {
    width: 448px;
    height: 320px;
    display: inline-block;
    position: absolute;
    margin-top: 60px;
    .itemContainer{
        width: 100%;
        height: 30px;
        .itembox{
            width: 16px;
            height: 16px;
            border-radius: 4px;
            display: inline-block;
            margin-right: 16px;
        }
        .itemStr{
            font-family: "LG SmartFont";
            font-size: 18px;
            color: rgb(201,201,201);
            display: inline-block;
            vertical-align: middle;
            width: 216px;
        }
        .itemStr2{
            width: 140px;
            margin-left: 10px;
            margin-right: 50px;
            text-align: end;
        }
    }
}
.empty_comment{
    position: absolute;
    text-align: right;
    width: 320px;
    top: 31px;
    left: 520px;
    font-size: 28px;
    line-height: 1.29;
    word-break: keep-all;
    font-family: "LG SmartFont Light";
}
@__DEV__: true;