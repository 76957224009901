@import '../../style/CommonStyle.module.less';

.tpanelmain{
    width: 1920px;
    height: 1080px;
    background-size: cover;
    background-image: url(../../../assets/banner/violet_bg.png);
}
.tHeader{
    position: relative;
    display: inline-flex;
    width: 100%;
    height: @panel_header_height;
    .backBtn {
		position: relative;
		margin-left: 73px;
		margin-top: 43px;
    }
    .backBtnTitle {
		position: relative;
		margin-left: 23px;
		font-size: 36px;
		margin-top: 63px;
		font-family: "LG SmartFont Bold";
    }
    .nobackBtnTitle {
        margin-left: 135px;
    }
}
@__DEV__: true;