.tInputField {
	width: 400px;
	height: 62px;
	font-size: 34px;
	font-family: 'LG SmartFont SemiBold';
	&.invalid{
		color: #A81010;
		input{
			color: #A81010  !important;
			opacity: 0.7;
		}
	}
}
@__DEV__: true;