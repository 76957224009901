@import '../../style/CommonStyle.module.less';
.panel {
    .medialistlayer{
        width: 1920px;
        position: absolute;
        height: 1080px - @panel_header_height;
        top: @panel_header_height;
        left:0px;
    }
    .dummy{
        height: 300px;
        width:100%;
    }

    .topBottomScrollArea{
        position:absolute;
        width: 100%;
        height: 100px;
        z-index: 21;
        &.touchGuide{
            opacity: 0.3;
            background-color: darkorange;
        }
    }
    .topScrollArea{
        transition: all 300ms ease;
        top: 100px;
        height: 73px;
    }
    .bottomScrollArea{
        bottom:0px;
        height: 40px;
    }
}
@__DEV__: true;