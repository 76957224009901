@import '../../style/CommonStyle.module.less';
@focusScale: 1.1;
@bgImageFocus: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6));
@fillPerImage: linear-gradient(46deg, rgba(91, 113, 200, 40%), rgba(171, 130, 238, 40%));

.tButton {
    display: inline-block;
    transition: all 200ms ease;
    background-size: contain;
    &.disabled{
        opacity: 0.5;
    }
    .marquee{
        margin-left: 12%;
        margin-right: 12%;
        >div>div{
            text-align: center;
        }
    }
}
.skipIntro {
    background-color: rgba(104, 100, 148, 0.2);
    width: 237px;
    height: 47px;
    line-height: 47px;
    border-radius: 43px  43px  43px  43px;
    border: 2px solid rgb(255, 255, 255);
    text-align: center;
    font-weight: normal;
    text-shadow: 0.1rem 0.1rem 0.7rem rgba(0, 0, 0, 0.65);
    box-shadow: 0.05rem 0.05rem 0.9rem rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    &.fillPer{
        background-image: @fillPerImage;
    }
    &:focus{
        transform: scale(@focusScale);
        background-image: @bgImageFocus;
    }
}
.normal {
    background-image:url(../../../assets/buttons/btn_2.png);
    width: 260px;
    height: 76px;
    text-align: center;
    font-family: "LG SmartFont Light";
    color: rgb(255, 255, 255);
    font-size: 24px;
    background-repeat: no-repeat;
    line-height: 64px;
    &:focus{
        background-image:url(../../../assets/buttons/btn_2_focus.png);
    }
}
.promotion {
    background-color: rgba(250, 250, 250, 0.2);
    width: auto;
    min-width: 300px;
    height: 60px;
    text-align: center;
    font-family: "LG SmartFont bold";
    color: rgb(255, 255, 255);
    font-size: 24px;
    line-height: 64px;
    border-radius: 50px 50px 50px 50px;
    border: 5px solid;
    &:focus{
        transform: scale(@focusScale);
        background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) ;
    }
}
.promotionPopup{
    background-color: rgba(250, 250, 250, 0.2);
    width: 300px;
    height: 60px;
    text-align: center;
    font-family: "LG SmartFont bold";
    color: rgb(255, 255, 255);
    font-size: 24px;
    line-height: 64px;
    border-radius: 50px 50px 50px 50px;
    border: 5px solid;
    &:focus{
        transform: scale(@focusScale);
        background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) ;
    }
}
.withArrow{
    background-image:url(../../../assets/buttons/btn_1.png);
    width: 524px;
    height: 124px;
    text-align: left;
    font-family: "LG SmartFont";
    color: rgb(255, 255, 255);
    font-size: 26px;
    background-repeat: no-repeat;
    >div{
        line-height: 113px;
        margin-left: 37px;
    }

    &:focus{
        background-image:url(../../../assets/buttons/btn_1_focus.png);
    }
}
.back{
    background-image:url(../../../assets/buttons/back.png);
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    &:focus{
        background-image:url(../../../assets/buttons/back_focus.png);
    }
}
.backArrow{
    position: relative;
    background: linear-gradient(0deg, rgba(95, 83, 83, 0.651), rgba(121, 103, 103, 0.575));
    font-family: "LG SmartFont SemiBold";
    font-size: 44px;
    width: 246px;
    height: 97px;
    color: rgb(255, 255, 255);
    border-radius: 60px;
    line-height: 97px;
    text-align: center;
    >div{
        margin-left:32px;
    }
    &:before{
        content: "";
        position: absolute;
        top: 54%;
        left: 20%;
        transform: translate(-50%, -50%) scaleX(-1);
        background-image: url('../../../assets/buttons/icon-player-right.png');
        width: 39px;
        height:59px;
    }
    &:focus{
    background: @bgImageFocus;
    }
}
.nextArrow{
    position: relative;
    background: linear-gradient(0deg, rgba(95, 83, 83, 0.651), rgba(121, 103, 103, 0.575));
    font-family: "LG SmartFont SemiBold";
    font-size: 44px;
    width: 246px;
    height: 97px;
    color: rgb(255, 255, 255);
    border-radius: 60px;
    line-height: 97px;
    text-align: center;
    >div{
        margin-right:32px;
    }
    &:before{
        content: "";
        position: absolute;
        top: 54%;
        left: 80%;
        transform: translate(-50%, -50%);
        background-image: url('../../../assets/buttons/icon-player-right.png');
        width: 39px;
        height:59px;
    }
    &:focus{
    background: @bgImageFocus;
    }
}
.hide{
    opacity: 0;
}
.interviewModifyBtn{
    width: 363px;
    height: 424px;
    border-radius: 30px;
    background-color: #eeedf1;
    &:focus{
        background: @FOCUS_COLOR;
        border-radius: 30px;
        >div>div{
            color: #eeedf1 !important;
        }
    }
}
.big{
    position: relative;
    background: linear-gradient(0deg, rgba(95, 83, 83, 0.651), rgba(121, 103, 103, 0.575));
    font-family: "LG SmartFont SemiBold";
    color: rgb(255, 255, 255);
    font-size: 44px;
    width: 550px;
    height: 110px;
    line-height: 110px;
    border-radius: 60px;
    text-align: center;
    &:focus{
    background: @bgImageFocus;
    }
}
@__DEV__: true;