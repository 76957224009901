.list {
	position: relative;
	border-radius: 24px;
	margin-bottom: 30px;
	background-color: rgba(255, 255, 255, 0.16);
	border: 2px solid rgb(129, 127, 131);
	color: rgba(197, 197, 197, 0.82);
	.listHead {
		position: relative;
		display: inline-flex;
		margin-top: 31px;
		width: 100%;
		justify-content: space-between;
		.title {
			position: relative;
			left: 29px;
			width: 50%;
			font-family: 'LG SmartFont SemiBold';
			font-size: 40px;
		}
		.switch {
			position: relative;
			top : -20px;
			width: 210px;
			height: 70px;
			border-radius: 26px;
			margin-left: 88px;
			font-size: 26px;
			font-family: "LG SmartFont";
		}
	}
}

.goal {
	width: 1780px;
	height: 410px;
}
.goalFocus{
	color: rgb(255, 255, 255);
	background-color: rgba(255, 255, 255, 0.3)
}

.alarm {
	width: 750px;
	height: 250px;
}
.alarmFocus{
	color: rgb(255, 255, 255);
	background-color: rgba(255, 255, 255, 0.3)
}

.contentContainer {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	border-radius: 20px;
	background-color: rgba(255,255,255,0.83);
	&:focus{
		div {
			opacity: 1 !important;
		}
		border-radius: 20px;
		box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.15);
		background-color: rgba(48, 24, 61, 0.281);
	}
}
.alarmContainer {
	margin-top: -25px !important;
	width: 710px;
	height: 130px;
	margin-left: 20px !important;
	background-color: rgba(87,75,75,0.27);
	box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.15);
	&:focus{
		margin-top: -25px !important;
		width: 710px;
		height: 150px;
	}
}
.goalContainer {
	margin-top: -15px !important;
	width: 1740px;
	height: 250px;
	background-color: rgba(66,49,49,0.20);
	box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.3);
	&:focus{
		padding: 0px 10px;
		width: 1740px;
		height: 290px;
	}

}

.alarmAddContainer {
	margin-top: -25px !important;
	width: 710px;
	height: 150px;
	margin-left: 20px !important;
	background-color: rgba(87,75,75,0.27);
	box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.15);
}
.goalAddContainer {
	margin-top: -15px !important;
	width: 1760px;
	height: 290px;
	margin-left: 10px;
	border-radius: 20px;
	background-color: rgba(87,75,75,0.27);
	box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.15);
}

@__DEV__: true;