@import '../../../style/CommonStyle.module.less';
@button-focus-size: 1.02;

.panel {
    .title {
        position: relative;
        width:100%;
        text-align: center;
        font-size: 36px;
        font-family:'LG SmartFont Bold';
    }
    .alarm {
        position: relative;
        display: inline-flex;
        width: 100%;
        .alarmTitle {
            position: relative;
            margin-left: 72px;
            margin-top: 15px;
            font-size: 30px;
            font-family: "LG SmartFont";
        }
        .alarmSwitch {
            position: relative;
            width: 250px;
            height: 72px;
            border-radius: 26px;
            margin-left: 88px;
            font-size: 26px;
            font-family: "LG SmartFont";
            &:focus{
                > div:first-child{
                    background-size: 100% 72px !important;
                }
            }
        }
    }
    .time {
        position: relative;
        width: 100%;
        display: inline-flex;
        .timeTitle {
            display: table-cell;
            position: relative;
            margin-left: 72px;
            margin-top: 150px;
            font-size: 30px;
            font-family: "LG SmartFont";
        }
        .timePicker{
            margin-left: 82px;
        }
    }
    .repeat {
        position: relative;
        width: 100%;
        .repeatTitle {
            position: relative;
            font-size: 30px;
            margin-left: 73px;
            font-family: "LG SmartFont";
        }
        .dayPicker {
            position: relative;
            margin-left: 73px !important;
            margin-top: 22px !important;
        }
    }
    .buttonContainer{
            position: relative;
            margin-top: 123px;
            text-align: center;
        .doneBtn {
            position: relative;
        }
    }
}
@__DEV__: true;