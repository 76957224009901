.tcheckbox{
	width: auto;
	.wrapper{
		width: auto;
		position: relative;
		.checkboxBG{
			display: inline-block;
			width: 78px;
			height: 78px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url("../../../assets/buttons/checkbox_ON.png");
		}
		.selected{
			width: 42px;
			height: 42px;
			position: absolute;
			top: 16px;
			left: 20px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url("../../../assets/buttons/CHECK_80.png");
		}
		.text{
			font-family: "LG SmartFont";
			font-size: 24px;
			display: inline;
			margin-left: 24px;
			vertical-align: top;
			line-height: 70px;
		}
	}
	&:focus{
		.checkboxBG{
			background-image: url("../../../assets/buttons/checkbox_FOCUS.png");
		}
	}
}
@__DEV__: true;