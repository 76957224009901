.arrow{
    width: 300px;
    height: 63px;
    background-size: contain;
    background-image:url(../../../assets/list/scrollGuideArrow.png);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    &.top{
        bottom: 0px;
        top: initial;
        transform: rotate(180deg) translateX(50%);
    }
    &.hover{
        background-image:url(../../../assets/list/scrollGuideArrow_focused.png);
    }
}
@__DEV__: true;