.panel{
	.InputField {
		position: relative;
		margin-left: 610px;
		margin-top: 200px;
		width: 700px;
		height: 62px;
		font-size: 34px;
		font-family: 'LG SmartFont SemiBold';
	}
	.button{
		position: relative;
		margin-top: 500px;
		text-align: center;
	}
}
@__DEV__: true;