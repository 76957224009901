@import '../../../style/CommonStyle.module.less';

.chart {
	.dashboards-chart-bg();
}
.date{
	position: absolute;
	top: 35px;
	left: 27px;
	font-size: 18px;
	font-family: "LG SmartFont";
}
.avg_info{
	position: absolute;
	top: 70px;
	left: 27px;
}
.title{
	position: relative;
	margin-right: 10px;
	font-size: 18px;
	font-family: "LG SmartFont Light";
}
.time{
	position: relative;
	width: 400px;
	margin-right: 10px;
	font-size: 40px;
	font-family: "LG SmartFont Bold";
	color: rgb(41,252,255);
}
.text_day{
	position: relative;
	top: 0px;
	width: 400px;
	font-size: 18px;
	font-family: "LG SmartFont Light";
}
.comment{
	position: absolute;
	text-align: right;
	top: 31px;
	right: 30px;
	font-size: 30px;
	line-height: 1.29;
	white-space: pre-wrap;
	font-family: "LG SmartFont Light";
}
.barContainer{
	position: relative;
	height:0px;
	top: 295px;
	display: flex;
	transform: rotateX(180deg)
}
.bar_weekly{
	position: relative;
	width: 24px;
	margin-right: 78px;
	left: 133px;
	background-image: linear-gradient(to bottom, rgb(112,52,112), rgb(251,55,123));
}
.bar_monthly{
	position: relative;
	width: 12px;
	margin-right: 11.2px;
	left: 97px;
	background-image: linear-gradient(to bottom, rgb(112,52,112), rgb(251,55,123));
}
.bar_yearly{
	position: relative;
	width: 20px;
	margin-right: 40px;
	left: 111px;
	background-image: linear-gradient(to bottom, rgb(54,50,107), rgb(251,55,123));
}
.listContainer{
	position: relative;
	width: 720px;
	height: 2px;
	top: 329px;
	left: 91px;
	background-color: rgb(107,107,107);
}
.lineContainer{
	display: flex;
	div:nth-child(12) {
		opacity: 0;
	}
}
.line_month{
	position: relative;
	top : -240px;
	left: 140px;
	height: 200px;
	margin-right: 160px;
	border: dashed 1px rgb(107,107,107);
}
.line_year{
	position: relative;
	top : -240px;
	left: 60px;
	height: 200px;
	margin-right: 58px;
	border: dashed 1px rgb(107,107,107);
}
.text_zero{
	position: relative;
	top: 309px;
	left: 70px;
	font-size: 18px;
	font-family: "LG SmartFont";
}
.weekly{
	display: inline-block;
	position: relative;
	width: 37px;
	top: 11px;
	font-size: 18px;
	left: 37px;
	text-align: center;
	font-family: "LG SmartFont";
	margin-right: 65px;
}
.monthly{
	display: inline-block;
	position: relative;
	width: 20px;
	top: 11px;
	font-size: 18px;
	left: 140px;
	text-align: center;
	font-family: "LG SmartFont";
	margin-right: 143px;
}
.yearly{
	display: inline-block;
	position: relative;
	top: 11px;
	width: 39px;
	font-size: 18px;
	text-align: left;
	left: 15px;
	font-family: "LG SmartFont";
	margin-right: 21px;
	text-align: center;
}
.avg_line{
	position: absolute;
	left: 91px;
	width: 720px;
	border: dashed 1px rgb(107,107,107);
}
.avg_text{
	position: absolute;
	width: 55px;
	background:transparent;
	line-height: 1.29;
	left: 28px;
	font-size: 14px;
	font-family: "LG SmartFont";
}
.hide{
	opacity: 0;
}
@__DEV__: true;