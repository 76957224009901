.tswitchitem{
    > div:first-child{  // switch focus
        //background-image: url("../../../assets/buttons/alarm_focus.png");
        background-color: transparent !important;
        border-radius: 26px;
        background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;
        //background-size: 100px 56px;
        //background-size: cover;
    }
    > div:nth-child(2) > div:nth-child(1) { // switch text
        text-align: right;
        color:white !important;
    }
    > div:nth-child(3){ //icon background
        >div{
            >div:nth-child(2){
                width: 83px !important;
                height: 34px !important;
                background-color: rgba(250, 250, 250, 0.2) !important;
                >div{
                    width: 34px;
                    font-size: 40px;
                    line-height: 34px;
                    left: 0px;
                    color: rgb(91,90,90) !important;
                }
            }
        }
    }

    &.selected{
        >div:nth-child(3)>div>div:nth-child(2)>div{
            left: 50px;
            color:white !important;
        }
    }
}
@__DEV__: true;