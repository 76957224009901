@import '../../style/CommonStyle.module.less';

.tooltipContainer{
	width: 380px;
	margin: 0 auto 0 auto;
	text-align: center;
	.tooltipWrap {
		position: relative;
		margin: 0 auto 0 auto;
		width: fit-content;
		min-width: 20%;
		max-width: 92%;
		height: auto;
		background-color: @TOOLTIP_BG_COLOR;
		border-radius: 10px;
		.text{
			word-wrap: break-word;
			font-family: "LG SmartFont";
			padding: 10px;
			text-align: center;
			font-size: 28px;
			line-height: 40px;
			color: rgb(0, 0, 0);
			white-space: pre-wrap;
		}
	}
}
.tooltipContainer::before {
	display: block;
	border-bottom: 13px solid @TOOLTIP_BG_COLOR;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	height: 0;
	width: 0;
	margin: 10px auto 0 auto;
	content: "";
}
@__DEV__: true;