@import '../../../../style/CommonStyle.module.less';

.answerItem{
  font-family: 'LG SmartFont SemiBold';
  position: relative;
  background-color: #fff;
  height: 78px;
  border-radius: 39px;
  color: #2f262b;
  margin-bottom: 30px;
  &:focus{
    background-image: @FOCUS_COLOR;
    color: #fff;
  }
  .icon{
    position: absolute;
    width: 60px;
    height: 60px;
    right: 64px;
    top: 10px;
  }
}
.active{
  background-image: @FOCUS_COLOR;
  box-shadow: 0 0 0 5px #787878 inset;
  color: #fff;
}
.text{
  width: 720px;
  font-size: 30px;
  line-height: 78px;
  padding-left: 40px;
  padding-right: 40px;
  &.hasIcon{
    width: 560px;
  }
}
@__DEV__: true;