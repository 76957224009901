@import '../../../style/CommonStyle.module.less';

.chart {
    .dashboards-chart-bg();
    height: 550px;
    .left{
        width: 400px;
        height: 100%;
        position: relative;
        float: left;
        .image{
            width: 362px;
            height: 349px;
            background-size: contain;
            margin: 0;
            position: absolute;
            left: 50%;
            top: 45%;
            transform: translate(-50%, -50%);
        }
        .styletext{
            position: absolute;
            bottom: 60px;
            width: 100%;
            text-align: center;
            font-size: 30px;
            font-family: "LG SmartFont Light";
        }
    }
    .right{
        width: calc(100% - 400px);
        height: 100%;
        float: right;
        border-radius: 0 32px 32px 0;
        background: linear-gradient(to top,rgba(255, 255, 255, 0.15),rgba(255, 255, 255, 0.15));
        display: flex;
        flex-direction: column;
        justify-content: center;
        .itemContainer{
            margin-left: 50px;
            margin-top: 10px;
            margin-bottom: 10px;
            .iconImg{
                width: 60px;
                height: 60px;
                background-size: contain;
                margin: 0px;
                float: left;
            }
            .textBlock{
                display:inline-block;
                width: 310px;
                margin-left: 30px;
                .title{
                    font-family: "LG SmartFont";
                    font-size: 18px;
                    color: rgb(167,167,167);
                    line-height: 18px;
                }
                .value{
                    font-family: "LG SmartFont";
                    font-size: 40px;
                    line-height: 50px;
                    display: inline-block;
                    &.marquee{
                        display: initial;
                    }
                }
                .value2{
                    font-family: "LG SmartFont";
                    font-size: 18px;
                    color: rgb(167,167,167);
                    display: inline-block;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
}
@__DEV__: true;