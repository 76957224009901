.panel{
	background-color:black;
	position: absolute;
	opacity: 0.8;
	top: 0;
	left: 0;
	z-index: 100;
  }
@keyframes spin {
	0% {
	  transform: rotate(0.25turn);
	}
	33% {
	  transform: rotate(0.5turn);
	}
	80% {
	  transform: rotate(0.95turn);
	}
	85% {
	  transform: rotate(1turn);
	}
	100% {
	  transform: rotate(1.25turn);
	}
  }
.spinner{
	z-index:101;
	background-image: url("../../../assets/icon_loading.png");
	width: 100px;
	height: 100px;
	position: absolute;
	background-size: cover;
	border-radius: 20.8125rem;
	overflow: hidden;
	left: 50%;
	top: 50%;
	animation: none 1.25s linear infinite;
	animation-name: spin;
	// animation-play-state: paused;
}
@__DEV__: true;