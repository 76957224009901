.list{
    background-color: transparent;
    position: relative;
    border-radius: 18px;
    margin-left: auto;
    margin-right: auto;
    .img{
        position: relative;
        width: 230px;
        height: 128px;
        margin-top : 15px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 18px;
        border: 2px solid rgba(71, 70, 71, 0.253);
        box-shadow: 0px 5px 6px rgba(65, 63, 63, 0.123);
    }
    .noneImg {
        position: relative;
        width: 230px;
        height: 128px;
        margin-top : 15px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 18px;
        background-color: rgba(255, 255, 255, 0.16);
        border: 2px solid rgba(59, 59, 59, 0.301);
        box-shadow: inset 0px 0px 25px rgba(202, 200, 200, 0.17);
        opacity: 0.5
    }
    .title{
        position: relative;
        width: 220px;
        margin-top : 20px;
        font-size: 18px;
        padding: 0px 12px;
        font-family: 'LG SmartFont Light';
    }
    .alarm{
        position: relative;
        font-size: 18px;
        padding: 0px 12px;
        font-family: 'LG SmartFont Light';
    }
    .noneContents{
        color: rgb(129, 127, 131);
    }

    .youtubelogo {
		z-index: 5;
		bottom: 95px;
		left: 10px;
		width: 42px;
		height: 29px;
		border-radius: 0px;
		border: 0px;
		background-size: contain;
		background-repeat: no-repeat;
		position:absolute;
		background-image: url("../../../assets/list/icon/icon_youtube.png");
	}
}
@__DEV__: true;