.panel{
	.PlanListContainer {
		position: relative;
		display: inline-flex;
		width: 95%;
		height: 563px;
		margin-top: 200px;
		margin-left: auto;
		margin-right: auto;
	}
}
@__DEV__: true;