@import '../../style/CommonStyle.module.less';
@itemHeight: 304px; // 284px
@itemHeightBig: 638px;

.listContainer {
    width: ~"calc(100% - 62px)";
    display: inline-block;
    position: relative;
    transition: transform 200ms ease-out;
    padding-left: 62px;
    .listTitlelayer{
        height: 30px;
        margin-top: 0px;
        margin-bottom: 0px;
        left: 14px;
        display: inline-block;
        position: relative;
        .listicon {
            display: inline-block;
            position: relative;
            width: 36px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../../../assets/list/icon/icon_subtitle.png");
        }
        .listTitle {
            color: rgb(255, 255, 255);
            display: inline-block;
            position: relative;
            width: 1700px;
            height: 20px;
            line-height: 20px;
            margin-left: 9px;
            letter-spacing: -1.4px;
            font-size: 28px;
        }
    }
    .achievementGraphlayer{
        position: relative;
        top: -8px;
        left: 420px;
        .progressgraph{
            position: relative;
            width: 650px;
            height: 10px;
            border-radius: 25px 25px 25px 25px;
            background-color: #c5c3c4;
            opacity: 0.22;
        }
        .textWrap{
            position: absolute;
            display: flex;
            top: -28px;
            left: 0px;
            .icon{
                width: 40px;
                height: 40px;
                background-image: url("../../../assets/run_img.png");
                background-size: cover;
                z-index: 10;
                margin-left: -7px;
            }
            .text {
                margin-left: 10px;
                font-size: 28px;
                margin-top: -3.5px;
            }
            .leftText{
                transform:translateX(-100%);
                margin-left: -45px;
                font-size: 28px;
                margin-top: -3.5px;
            }
        }
        .progressbar{
            position: absolute;
            top: 0px;
            left: 0px;
            height: 10px;
            width: 500px;
            border-radius: 25px 25px 25px 25px;
            background-color: #be54b0
        }
    }
    .listMain{
        width: 100%;
        height: @itemHeight;
        white-space: nowrap;
        &.bigItem{
            height: @itemHeightBig;
        }
    }
    &.vertical{
        width: auto;
        height: auto;
        padding-left: 0px;
        overflow: hidden;
        min-width: 1800px;
        .listMain{
            white-space: initial;
            height: auto;
        }
    }
    .leftRightScrollArea{
        position:absolute;
        background-color: blue;
        top: 0px;
        opacity: 0.3;
        width: 100px;
        height: 100%;
        z-index: 21;
        &.hide{
            opacity: 0;
        }
    }
    .leftScrollArea{
        left:0px;
    }
    .rightScrollArea{
        right:0px;
    }
}
@__DEV__: true;