@resultItemWidth: 200px;
@resultItemLRMargin: 14px;
@resultItemSecondChildWidth: @resultItemWidth - @resultItemLRMargin;

.resentSearchsContainer{
	//position: relative;
	display: inline-block ;
	width: auto;
	margin-left: 10px;
	margin-right: 10px;
	.listItem{
		position: relative;
		display: inline-block ;
		//background-color: red;
		width: auto !important;
		height: 49px;
		box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
		background-color: rgba(143,143,143, 0.48);
		border: 2px solid rgba(114, 111, 111, 0.292);
		border-radius: 8px;
		.searchResult{
			position: relative;
			display: inline-block;
			width: @resultItemWidth;
			margin: 0px 0px;
			padding: 9px @resultItemLRMargin 9px @resultItemLRMargin;
			color: white;
			font-size: 25px;
			height: auto;
			font-family: "LG SmartFont Light";
			//background-size: 123px 56px;
			//background-color: blue;
			> div{
				width: @resultItemSecondChildWidth !important;
				display: table-caption !important;
			}
			> div:first-child{
						width: @resultItemWidth !important;
						margin-top: -1px;
						//background-image: url("../../../assets/buttons/alarm_focus.png");
						background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;
						//background-size: 123px 56px;
					}
			&:focus{
				color: white
				//z-index: 1001;
			}
		}
		.deletebutton {
			position: relative;
			display: inline-block;
			//display: table-caption;
			width: 44px;
			height: auto;
			margin: auto;
			font-size: 25px;
			text-transform: uppercase;
			font-family: "LG SmartFont Light";
			margin: 0px 0px;
			padding: 9px 14px 9px 14px;
			//z-index: 1001;
			//background-size: 123px 56px;
			//background-color: rgb(48, 48, 49);
			> div{
				margin: auto;
				width: 44px !important;
				display: table-caption !important;
			}
			> div:first-child{
				margin-top: -1px;
				//background-image: url("../../../assets/buttons/alarm_focus.png");
				background: linear-gradient(50deg, rgba(90,111,146,0.6), rgba(90,111,146,0.77), rgba(112,77,116,0.77), rgba(112,77,116,0.6)) !important;
				//background-size: 123px 56px;
			}
			&:focus{
				color: white
			}
		}
	}
}

@__DEV__: true;