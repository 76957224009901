@import '../../../style/CommonStyle.module.less';

.panel {
    .text {
        position: relative;
        color: darkgray;
        font-size: 30px;
        margin-left: 73px;
        font-family: "LG SmartFont";
    }
    .alarmListBg {
        position: relative;
        display: block;
        width: 750px;
        top: 139px;
        height: auto;
        border-radius: 24px;
        margin: auto 50%;
        transform: translateX(-50%);
        .listContainer{
            position: relative;
            padding: 0px;
            transform: translateX(0%);
            top: 0px;
        }
    }
}
@__DEV__: true;