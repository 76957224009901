@import '../../style/CommonStyle.module.less';

@WIDTH: 800px;
@HEIGHT: 400px;

.infopopup{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: @WIDTH !important;
  height: @HEIGHT;
  border-radius: 20px;
  border: 2px solid rgba(212, 214, 221, 0.753);
  background: linear-gradient(to bottom, rgba(243, 243, 243, 0.66), rgba(212, 212, 212, 0.65), rgba(177, 176, 185, 0.76), rgba(166, 165, 173, 0.51), rgba(153, 153, 160, 0.4)) !important;
  background-size: cover !important;
  opacity: 1;
  margin-bottom: calc(50vh - @HEIGHT/2) !important;
  >div{
    height: 100%;
    >div{
      height: 100%;
      >div{
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
  &.darkBackground{
    background: @GREY_COLOR !important;
  }
}
.messagelayer{
	display: flex;
  flex-flow: column;
  justify-content: center;
	width: 100% !important;
	height: 260px !important;
  gap:40px;
	&.noButton{
		margin-bottom: -33px;
	}
	&.hasButton{
		width: ~"calc(100% - 800px)";
	}
  .title{
    text-align: center;
		font-family: "LG SmartFont SemiBold";
		font-size: 40px;
		white-space: pre-wrap;
		line-height: 1.4;
		width: 100%;
		color: rgb(255, 255, 255);

  }
	.message{
		position: relative;
		font-family: "LG SmartFont";
		font-size: 30px;
		white-space: pre-wrap;
		line-height: 1.4;
		text-align: center;
		width: 100%;
		color: rgb(255, 255, 255);
    b{
      text-align: center;
      font-family: "LG SmartFont SemiBold";
      font-size: 40px;
    }
	}
  .textAlignLeft{
    text-align: left;
  }
	.noticeAlert {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
	}

}
.buttonlayer{
	position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
	width: 800px !important;
	margin-top: 10px;
	.buttonWrapper{
    height: 100% !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
	}
}


@__DEV__: true;