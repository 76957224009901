@import '~@enact/sandstone/styles/mixins.less';

.noMediaText {
	height: 304px;
	text-align: center;
	line-height: 304px;
}

.mediaImage {
	position: relative;
    display: inline-block;
    width: 360px;
    height: 304px;
    margin: 0;
	font-family: "LG SmartFont";
	&.bigItem,
	&.planItem{
		height: 638px;
	}
}
.hide {
	visibility: hidden;
	opacity: 0;
}
.imageItem {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding-top: 0px;
	// will-change: transform;
	.toDayStr {
		position: absolute;
		margin-left: -16px;
		margin-top: -5px;
		z-index: 10;
		width: 300px;
		height: 150px;
		background-size: contain;
		background-image: url("../../../assets/list/today.png");
		background-repeat: no-repeat;
	}
	.newTodayStr {
		margin-left: 209px;
		margin-top: 10px;
		width: 150px;
		height: 75px;
		background-image: url("../../../assets/list/new_today_Icon.png");
	}
	.top10Number{
		position: absolute;
		margin-left: -16px;
		margin-top: -20px;
		z-index: 10;
		width: 146px;
		height: 146px;
		background-size: contain;
		&.index_1{
			background-image: url("../../../assets/list/numbers/image_01.png");
		}
		&.index_2{
			background-image: url("../../../assets/list/numbers/image_02.png");
		}
		&.index_3{
			background-image: url("../../../assets/list/numbers/image_03.png");
		}
		&.index_4{
			background-image: url("../../../assets/list/numbers/image_04.png");
		}
		&.index_5{
			background-image: url("../../../assets/list/numbers/image_05.png");
		}
		&.index_6{
			background-image: url("../../../assets/list/numbers/image_06.png");
		}
		&.index_7{
			background-image: url("../../../assets/list/numbers/image_07.png");
		}
		&.index_8{
			background-image: url("../../../assets/list/numbers/image_08.png");
		}
		&.index_9{
			background-image: url("../../../assets/list/numbers/image_09.png");
		}
		&.index_10{
			background-image: url("../../../assets/list/numbers/image_10.png");
		}
	}
	.thumbContainer {
		margin: 0;
		width: 100%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url("../../../assets/list/frame.png");
		// transition: transform 200ms ease-in-out;
		&.specialBtnContainer{
			background-image: initial;
			//background-color: yellow;
			width: 340px;
			height: 304px;
			//right: 50px;
			background-color: rgba(143, 143, 143, 0.28);
			border: 2px solid rgba(114, 111, 111, 0.292);
			border-radius: 25px;
			box-shadow: inset 0px 0px 20px rgba(212, 200, 200, 0.15);
			margin: 14px 10px 61px 10px;
		}
		&.thumbContaineryoutubeChannel{
			width: 360px;
			height: 304px;
			background-image: url("../../../assets/list/frame_channel.png");
		}
		&.thumbContainerApp{
			width: 360px;
			height: 304px;
			background-image: url("../../../assets/list/fitness_banner_round.png");
		}
	}
	.image {
		position: absolute;
		z-index: 5;
		width: 301px; // old = 84%
		height: 169px; // old = 59%
		margin-left: 30px; // old = 8%
		margin-top: 34px; // old = 6%
		border-radius: 18px; // old = 7%
		border: 0px;
		/* yhcho_temp */
		// border: 1px solid rgb(115, 173, 33);
		transition: opacity 500ms ease-in-out;
	}
	.subtime {
		position: absolute;
		display: block;
		z-index: 16;
		background-color: rgba(39, 39, 39, 0.7);
		width: auto;
		height: 30px !important;
		line-height: 35px !important;
		right: 32px;
		bottom: 104px;
		font-size: 18px;
		text-align: center;
		margin: auto;
		padding-bottom: 2px;
		padding-left: 9px;
		padding-right: 9px;
		color: rgb(255, 255, 255);
		transition: initial;
		border-radius: 18px; // old = 7%
		border: 0px;
	}
	.badgelogo {
		z-index: 5;
		bottom: 105px;
		left: 38px;
		width: 42px;
		height: 29px;
		border-radius: 0px;
		border: 0px;
		background-size: contain;
		background-repeat: no-repeat;
		position:absolute;
	}
	.applogo{
		position: relative;
		width: 148px;
		height: 148px;
		margin-left: 29%;
		margin-top: 10%;
		border: 2px solid rgb(196, 191, 205);
	}
	.youtubeChannel{
		position: relative;
		width: 148px;
		height: 148px;
		margin-left: 29%;
		margin-top: 10%;
		border: 2px solid rgb(196, 191, 205);
		border-radius: 70%;
	}
	.youtubeVideo {
		position: absolute;
		z-index: 5;
		width: 300px; // old = 84%
		height: 168px; // old = 59%
		margin-left: 31px; // old = 8%
		margin-top: 35px; // old = 6%
		border-radius: 18px; // old = 7%
		border: 0px;
		/* yhcho_temp */
		// border: 1px solid rgb(115, 173, 33);
		transition: opacity 500ms ease-in-out;
	}
	.playIcon {
		opacity: 0;
		width: 91px;
		height: 106px;
		z-index: 6;
		position: absolute;
		top: 21%;
		left: 38%;
		transform: translate(-50% -50%);
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url("../../../assets/list/ico-play.png");
		&.channelPlayIcon{
			top: 25%;
			left: 38%;
			width: 96px;
			height: 78px;
			background-image: url("../../../assets/list/icon/icon_channel.png");
		}
		&.moreIcon{
			opacity: 1;
			left: 39%;
			width: 83px;
			height: 106px;
			background-image: url("../../../assets/list/icon/make routine_Banner.png");
		}
		&.addPlanIcon{
			opacity: 1;
			left: 34%;
			width: 116px;
			height: 148px;
			background-image: url("../../../assets/list/icon/make routine_Banner.png");
		}
		&.playing{
			opacity: 1;
			background-image: url("../../../assets/list/ico-playing.png");
		}
		&.appIcon{
			top: 25%;
			left: 38%;
			width: 96px;
			height: 78px;
			background-image: url("../../../assets/list/icon/icon_app.png");
		}
	}
	.addToFavoriteBtn{
		opacity: 0;
		width: 270px;
		height: 38px;
		z-index: 6;
		position: absolute;
		display: flex;
		bottom: 4px;
		left: 50%;
		transform: translateX(-43%);
		border-radius: 20px;
		/* top: initial; */
		background-color: rgba(112, 104, 104, 0.7);
//		background-image: url("../../../assets/list/btn-frame-1.png");
		.favIcon{
			width: 26px;
			height: 25px;
    		margin-left: 9px;
    		margin-top: 5px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url("../../../assets/list/ico_favorite.png");
			&.removeFav{
				background-image: url("../../../assets/list/ico_favorite_fill.png");
			}
		}
		.favText {
			font-size: 17px;
			min-width: 223px;
			letter-spacing: -0.9px;
			text-align: center;
			color: rgb(209, 209, 209);
			margin-top: 2.1px;
			//margin-left: 2px;
		}
		&.focus{
			width: 295px;
			height: 41px;
			border-radius: 20px;
			background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
//			background-image: url("../../../assets/list/btn-focus.png");
			top: initial;
			left: 48.1%;
			bottom: 4px;
			.favIcon{
				width: 27px;
				height: 27px;
				margin-left: 9px;
				margin-top: 6px;
				background-image: url("../../../assets/list/ico_favorite_focus.png");
				&.removeFav{
					background-image: url("../../../assets/list/ico_favorite_fill_focus.png");
				}
			}
			.favText{
				margin-top: 5px;
				margin-left: 2px;
				font-size: 19px;
				text-align: center;
				color: rgb(255, 255, 255);
			}
		}

	}
	.captionContainer {
		position: absolute;
		z-index: 15;
		height: 40px !important;
		width: 83%;
		bottom: 57px;
		left: 33px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.caption {
			position: absolute;
			display: block;
			width: 100%;
			height: 40px;
			font-size: 20px;
			letter-spacing: -0.5px;
			color: rgb(196, 191, 205) !important;
			z-index: 15;
			text-overflow: ellipsis;
			overflow: hidden;
			justify-content: center;
			white-space: nowrap;
			pointer-events: none;
		}
		&.channel{
			width: 212px;
			left: 51%;
			transform: translateX(-50%);
			.caption{
				font-size: 22px;
				line-height: 27px;
				text-align: center;
			}
		}
		&.app{
			width: 212px;
			left: 51%;
			transform: translateX(-50%);
			.caption{
				font-size: 22px;
				line-height: 27px;
				text-align: center;
			}
		}
		&.innerText{
			bottom: 30px;
			.caption{
				text-align: center;
			}
		}
	}
	.playerRepeatContainer{
		position: absolute;
		z-index: 15;
		height: 55px !important;
		width: 300px !important;
		bottom: 101px;
		left: 31px;
		font-size: 16px;
		line-height: 40px;
		padding: 0px 0px;
		margin: 0px 0px !important;
		font-family: "LG SmartFont";
		float: left;
		text-align: left;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.555), rgba(0, 0, 0, 0)) !important;
		border-radius: 0px 0px 18px 18px;
		.repeatImage{
			width: 23px;
			height: 18px;
			margin-left: 10px;
			margin-right: 10px;
			background-size: contain;
			background-image: url("../../../assets/list/icon/icon_repeat.png");
		}
		>div{
			display: inline-block;
			vertical-align: middle;
			margin-top: 17px;
			text-shadow: -0.8px 0 rgb(196, 196, 196), 0 0.8px rgb(196, 196, 196), 0.8px 0 rgb(196, 196, 196), 0 -0.8px rgb(196, 196, 196);
		}
	}
}
.repeatCountList{
	position:absolute;
	bottom: 105px;
	left: 35px;
	z-index: 101;
	background-image: url("../../../assets/list/icon_list.png");
	background-repeat: no-repeat;
	background-position: 7px 7px;
	width: 68px;
	height: 30px;
	background-color: black;
	opacity: 0.6;
	border-radius: 5px;
	>span{
		position:absolute;
		right: 10px;
		top: -1px;
		font-family: "LG SmartFont";
		font-size: 18px;

	}
}
.bigItem{
	.thumbContainer {
		background-image: url("../../../assets/list/frame-big.png");
		.image {
			height: 500px;
			background-size: cover;
			margin-top: 35px;
			margin-left: 30px;
		}
	}
	.playIcon {
		top: 36%;
	}
}
.planItem{
	.imageItem{
		padding-left: 12px;
		padding-right: 12px;
	}
	.thumbContainer {
		width: 336px;
		height: 563px;
		background-size: cover;
		background-color: rgba(143, 143, 143, 0.28);
		border: 2px solid rgba(114, 111, 111, 0.292);
		border-radius: 25px;
		box-shadow: inset 0px 0px 20px rgba(202, 200, 200, 0.15);
		margin: 18px 0px 48px 0px;
		background-image: initial;
		.image {
			background-size: cover;
			margin-top: 15px;
			margin-left: 15px;
			height: 530px;
			width: 306px;
			border-radius: 20px;
		}
		&.specialBtnContainer{
			background-image: initial;
			//background-image: url("../../../assets/list/frame-big.png");
			background-size: cover;
			background-color: rgba(143, 143, 143, 0.28);
			border: 2px solid rgba(114, 111, 111, 0.219);
			border-radius: 25px;
			box-shadow: inset 0px 0px 20px rgba(202, 200, 200, 0.15);
			margin: 18px 0px 48px 0px;
			//background-color: rgba(126, 126, 126, 0.315);
		}
	}
	.playIcon {
		top: 36%;
	}
	.planItemDetail{
		z-index: 101;
		position: absolute;
		display: block;
		margin-top: 212px;
		margin-left: 15px;
		width: 306px;
		height: 334px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
		background: linear-gradient(0deg, rgba(15, 15, 15, 0.62), rgba(15, 15, 15, 0.62), rgba(15, 15, 15, 0.62), rgba(36, 36, 36, 0)) !important;
		.planTitle{
			height: 65px;
			padding: 31px 10px 0px 10px;
			margin-bottom: 0px;
			text-align: left;
			font-size: 26px;
			font-family: "LG SmartFont SemiBold";
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			pointer-events: none;
		}
		.planList{
			font-size: 18px;
			margin-bottom: 9px;
			margin-left: 10px;
			font-family: "LG SmartFont";
			.plancountcontainer{
				width: auto;
				height: 100%;
				margin-left: 10px;
				margin-right: 10px;
				font-size: 16px;
				font-family: "LG SmartFont";
				float: right;
				text-align: right;
				.repeatImage{
					width: 23px;
					height: 18px;
					margin-right: 10px;
					background-size: contain;
					background-image: url("../../../assets/list/icon/icon_repeat.png");
				}
				>div{
					display: inline-block;
					vertical-align: middle;
					font-size: 0.70833rem;
					height: 100%;
					font-family: "LG SmartFont";
				}
			}
			.planlisttitle{
				width: auto;
				font-size: 17px;
				font-family: "LG SmartFont";
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				pointer-events: none;
			}
		}
	}

	.innerText{
		bottom: 170px !important;
		.caption{
			text-align: center;
			font-family: "LG SmartFont";

		}
	}
	.subtime{
		opacity: 0;
	}
}
.forceFocus {
// :global(.spottable):focus{
	z-index: 20;
	:not(.favFocus) .thumbContainer {
		/*transform: scale(1.2);*/
		background-image: url("../../../assets/list/focus-frame.png");
		width: 403px; //
		height: 100%; //
		.image {
			width: 348px; // old = 83%
			height: 196px; // old = 58.5%
			margin-top: 18px; // old = 8%
			margin-left: 27px;
		}
		.badgelogo {
			left: 35px;
			bottom: 94px;
		}
		.applogo{
			width: 178px;
			height: 178px;
			margin-left: 25.5%;
			margin-top: 6%;
			border: 1px solid rgb(104, 99, 116);
		}
		.youtubeChannel{
			width: 176px;
			height: 176px;
			margin-left: 26%;
			margin-top: 6%;
			border: 1px solid rgb(104, 99, 116);
			border-radius: 70%;
		}
		&.thumbContaineryoutubeChannel {
			margin: 0px 18px;
			width: 360px;
			height: 304px;
			background-image: url("../../../assets/list/focus_frame_channel.png");
		}
		&.thumbContainerApp{
			margin: 0px 18px;
			width: 360px;
			height: 304px;
			background-image: url("../../../assets/list/focus_fitness_banner_round.png");
		}
		&.specialBtnContainer {
			background-image: initial;
			border: 2px solid rgba(114, 111, 111, 0.295);
			border-radius: 25px;
			box-shadow: inset 0px 0px 20px rgba(24, 24, 24, 0.15);
			width: 380px;
			height: 304px;
			margin: 1px 10px 48px 10px;
			background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
		}
		.playerRepeatContainer{
			z-index: 15;
			height: 55px !important;
			width: 348px !important;
			bottom: 90px;
			left: 27px;
			font-family: "LG SmartFont";
			font-size: 17px;
		}
		.newTodayStr{
			width: 168px;
			margin-left: 235px;
			margin-top: -3px;
		}
	}
	.favFocus{
		.thumbContainer{
			//transform: scale(1.05);
			background-image: url("../../../assets/list/selected_frame_test.png");
			width: 408px; //
			height: 100%; //
			/*&.rightTransformOrigin{
				transform-origin: right;
			}*/
			.image {
				width: 328px; // old = 83%
				height: 188px; // old = 58.5%
				margin-top: 25px; // old = 8%
				margin-left: 28px;
			}
			&.thumbContaineryoutubeChannel{
				background-image: url("../../../assets/list/focus_frame_channel.png");
				width: 360px; //
				height: 304px; //
				margin-left: 18px;
				.youtubeChannel{
					width: 176px;
					height: 176px;
					margin-left: 26%;
					margin-top: 6%;
					border: 1px solid rgb(104, 99, 116);
					border-radius: 70%;
				}
			}
			&.thumbContainerApp{
				margin: 0px 18px;
				width: 360px;
				height: 304px;
				background-image: url("../../../assets/list/focus_fitness_banner_round.png");
				.applogo{
					width: 178px;
					height: 178px;
					margin-left: 25.5%;
					margin-top: 6%;
					border: 1px solid rgb(104, 99, 116);
				}
			}
			.planItemDetail{
				width: 340px;
				margin-top: 221px;
				margin-left: 20px;
				background: linear-gradient(0deg, rgba(15, 15, 15, 0.69), rgba(15, 15, 15, 0.69), rgba(15, 15, 15, 0.69), rgba(36, 36, 36, 0)) !important;
			}
			.newTodayStr{
				height: 150px;
				height: 75px;
				margin-left: 228px;
				margin-top: 3px;
			}
		}
	}
	.captionContainer{
		bottom: 47px;
		.caption {
			color: rgb(255, 255, 255) !important;
		}
	}

	.playIcon{
		opacity: 1;
		&.moreIcon{
			background-color: transparent;
		}
		&.addPlanIcon{
			background-color: transparent;
		}
	}
	.addToFavoriteBtn{
		opacity: 1;
	}
	&.bigItem {
		:not(.favFocus) .thumbContainer {
			background-image: url("../../../assets/list/focus_frame_big.png");
			.image {
				height: 527px;
				margin-left: 28px;
				width: 345px;
			}
		}
		.favFocus .thumbContainer {
			background-image: url("../../../assets/list/frame-big_btn_selected.png");
			.image {
				height: 520px;
				margin-left: 28px;
			}
		}
	}
	&.planItem {
		:not(.favFocus) .thumbContainer {
			border: 2px solid rgba(114, 111, 111, 0.292);
			border-radius: 25px;
			box-shadow: inset 0px 0px 20px rgba(202, 200, 200, 0.15);
			margin: 13px 0px 43px 0px;
			width: 380px;
			height: 583px;
			background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;
			background-image: initial;
			.image {
				background-size: cover;
				margin-top: 15px;
				margin-left: 20px;
				height: 540px;
				width: 340px;
				border-radius: 20px;
			}
			.caption{
				margin-top: 12px;
			}
			&.specialBtnContainer {
				background-image: initial;
				border: 2px solid rgba(114, 111, 111, 0.292);
				border-radius: 25px;
				box-shadow: inset 0px 0px 20px rgba(202, 200, 200, 0.15);
				width: 380px;
				background: linear-gradient(50deg, rgba(90, 111, 146, 0.6), rgba(90, 111, 146, 0.77), rgba(112, 77, 116, 0.77), rgba(112, 77, 116, 0.6)) !important;

			}
			.planItemDetail{
			width: 340px;
			margin-top: 221px;
			margin-left: 20px;
				background: linear-gradient(0deg, rgba(15, 15, 15, 0.69), rgba(15, 15, 15, 0.69), rgba(15, 15, 15, 0.69), rgba(36, 36, 36, 0)) !important;
			}
		}
	}
	.repeatCountList{
		bottom: 97px;
		left: 35px;
	}
}
.emphasisFont{
	font-weight: bold;
	font-size: 24px;
}
@__DEV__: true;